import { gql, useQuery } from '@apollo/client';
import { Testimonial } from './types';
import { CollectionResponse } from '../types';

interface QueryResult {
  testimonials: CollectionResponse<Testimonial>;
}

export function useTestimonial() {
  return useQuery<QueryResult>(query);
}

const query = gql`
  query GetTestimonials {
    testimonials {
      data {
        id
        attributes {
          ClientName
          TestimonialCopy
        }
      }
    }
  }
`;
