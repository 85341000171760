import React from 'react';
import { Typography, Grid, styled } from '@mui/material';
import { PortfolioItem } from '../../api';
import { ButtonLink, useMediaUrl } from '../../shared';

type Props = {
  portfolioslide: PortfolioItem;
};

const Image = styled('img')(() => ({
  position: 'relative',
  borderRadius: '1rem',
  maxWidth: '100%',
  maxHeight: '600px',
  height: 'auto',
}));

export const PortfolioSlideLayout: React.FC<Props> = ({ portfolioslide }) => {
  const imageUrl = useMediaUrl(portfolioslide.HompageImage.data.attributes);
  return (
    <Grid container>
      <Grid item xs={12} md={6} justifyContent="center">
        <Image src={imageUrl} alt={portfolioslide.Banner.Headline} />
      </Grid>
      <Grid item xs={12} md={6} flexDirection="column" alignItems="center" justifyContent="center">
        <Typography variant="h4">{portfolioslide.Banner.Headline}</Typography>
        <Typography>{portfolioslide.HomepageSynopsis}</Typography>
        <ButtonLink to={'/portfolio/' + portfolioslide.Slug}>
          See how we helped {portfolioslide.ClientName}
        </ButtonLink>
      </Grid>
    </Grid>
  );
};
