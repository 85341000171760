import React from 'react';
import { Box, Paper, Typography, Card, CardActions, Link } from '@mui/material';
import { icons } from '../../icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Robot } from '../../api';
import { useMediaUrl } from '../../shared';
type Props = {
  robot: Robot;
};

export const RobotCard: React.FC<Props> = ({ robot }) => {
  const robot1Url = useMediaUrl(robot.Robot1.data.attributes);
  const robot2Url = useMediaUrl(robot.Robot2.data.attributes);
  return (
    <Link href={robot.link} sx={{ textDecoration: 'none', width: '100%' }}>
      <Box display="flex" flexDirection="column" position="relative" sx={{ width: '100%' }}>
        <Paper
          variant="robotCard"
          sx={{
            width: '100%',

            background: 'URL(' + robot1Url + ')',
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
            '&:hover': {
              background: 'URL(' + robot2Url + ')',
              backgroundPosition: 'top center',
              backgroundRepeat: 'no-repeat',
            },
          }}
        >
          <Card>
            <Box p={2}>
              <Typography variant="h4">{robot.Headline}</Typography>
              <Typography>
                <FontAwesomeIcon icon={icons.check as IconProp} /> {robot.Bullet1}
              </Typography>
              <Typography>
                <FontAwesomeIcon icon={icons.check as IconProp} /> {robot.Bullet2}
              </Typography>
              <Typography>
                <FontAwesomeIcon icon={icons.check as IconProp} /> {robot.Bullet3}
              </Typography>
              <Typography>
                <FontAwesomeIcon icon={icons.check as IconProp} /> {robot.Bullet4}
              </Typography>
            </Box>

            <CardActions>
              <Typography>{robot.LinkText}</Typography>
            </CardActions>
          </Card>
        </Paper>
      </Box>
    </Link>
  );
};
