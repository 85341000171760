import React, { useMemo } from 'react';
import { CTASection, Markdown, PageBanner } from '../shared/layout/components';
import { Container, Box, Grid, Typography, Paper } from '@mui/material';
import { icons } from '../icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useParams } from 'react-router-dom';
import { LoadingIndicator, Meta } from '../shared';
import { useCareerItem } from '../api';

export const CareersDetailPage: React.FC = () => {
  const { slug } = useParams();
  const careerItemQuery = useCareerItem(slug!);
  const career = useMemo(() => {
    if (!careerItemQuery.data?.careers.data.length) return null;

    return careerItemQuery.data.careers.data[0];
  }, [careerItemQuery]);
  if (!careerItemQuery.data) return <LoadingIndicator />;
  return (
    <Box>
      <Meta seo={career?.attributes.SEO} />
      <PageBanner
        headline={career?.attributes.Banner.Headline}
        subCopy={career?.attributes.Banner.Subhead}
      />
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} lg={8} flexDirection="column">
            <Markdown children={career?.attributes.Copy!} />
          </Grid>
          <Grid item xs={12} lg={4} flexDirection="column">
            <Paper variant="roundedGrey">
              <Typography variant="h3">Apply Today</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <CTASection
        title="We are ready to help you."
        linkCopy="contact"
        link="/contact-us"
        img={icons.plane as IconProp}
      />
    </Box>
  );
};
