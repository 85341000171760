import React from 'react';
import { Stack } from '@mui/material';
import { AppHeader } from './header';
import { Footer } from '../../shared/layout/footer';

type Props = {
  children: React.ReactNode;
};

export const Shell: React.FC<Props> = ({ children }) => {
  return (
    <Stack m={0} p={0}>
      <AppHeader />
      {children}
      <Footer />
    </Stack>
  );
};
