import { gql, useQuery } from '@apollo/client';
import {
  DYNAMICS_FORM_FRAGMENT,
  FILE_FIELDS_FRAGMENT,
  MEETING_EMPLOYEE_FRAGMENT,
  SEO_FIELDS_FRAGMENT,
} from '../fragments';
import { EntityItem } from '../types';
import { ContactPage } from './types';

interface QueryResult {
  contactPage: EntityItem<ContactPage>;
}

export function useContact() {
  return useQuery<QueryResult>(query);
}

const query = gql`
  ${FILE_FIELDS_FRAGMENT}
  ${SEO_FIELDS_FRAGMENT}
  ${DYNAMICS_FORM_FRAGMENT}
  ${MEETING_EMPLOYEE_FRAGMENT}
  query GetContact {
    contactPage {
      data {
        id
        attributes {
          FormCopy
          Banner {
            Headline
            Subhead
          }
          SEO {
            ...SEOFields
          }
          DynamicsForm {
            data {
              ...DynamicsFormField
            }
          }
          MeetingEmployee {
            data {
              ...MeetingEmployeeField
            }
          }
        }
      }
    }
  }
`;
