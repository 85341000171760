import React from 'react';
import { Typography, Paper, Box } from '@mui/material';
import { PageBanner } from '../../api';
import { ButtonLink } from '../../shared';

type Props = {
  banner: PageBanner;
};

export const HomeBanner: React.FC<Props> = ({ banner }) => (
  <Paper variant="homeHeader">
    <Box sx={{ maxWidth: '1200px', padding: '12rem 1rem' }}>
      <Paper variant="buildingBG"></Paper>
      <Typography variant="h1">{banner.Headline}</Typography>
      <Typography>{banner.Subhead}</Typography>
      {banner.BannerButton && (
        <ButtonLink to={banner.BannerButton.buttonLink}>
          {banner.BannerButton.buttonCopy}
        </ButtonLink>
      )}
    </Box>
  </Paper>
);
