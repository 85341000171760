import React from 'react';
import { Card, Box, Stack, CardContent, CardActions, Typography, Link } from '@mui/material';
import { EnumDisplay, Image } from '../../shared';
import { InsightArticle } from '../../api/insights';

type Props = {
  insight: InsightArticle;
};

export const InsightsCard: React.FC<Props> = ({ insight }) => {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Image
        file={insight.image.data.attributes}
        sx={{ borderRadius: '1rem', width: '100%', height: 'auto' }}
      />

      <Card sx={{ margin: '-4rem 1rem 0 1rem' }}>
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          sx={{
            backgroundColor: 'secondary.main',
            '& p': { color: 'common.white', margin: '0' },
          }}
          justifyContent="space-between"
          p={2}
        >
          <Typography>
            <EnumDisplay value={insight.InsightType} /> by {insight.employee.data.attributes.name}
          </Typography>
          <Typography>{insight.TimeToReadMin}-min read</Typography>
        </Stack>
        <CardContent sx={{ backgroundColor: 'common.white' }}>
          <Link href={'/insights/' + insight.Slug} sx={{ textDecoration: 'none' }}>
            <Typography variant="h3" sx={{ color: 'secondary.main' }}>
              {insight.Title}
            </Typography>
          </Link>
          <Typography>{insight.Description}</Typography>
        </CardContent>
        <CardActions>
          <Link href={'/insights/' + insight.Slug} sx={{ textDecoration: 'none' }}>
            <Typography>{insight.LinkCopy}</Typography>
          </Link>
        </CardActions>
      </Card>
    </Box>
  );
};
