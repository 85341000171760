import React from 'react';
import { Paper, Typography, styled, Link, Grid } from '@mui/material';
import { PrototypeCallout } from '../../api';
import { useMediaUrl } from '../../shared';

type Props = {
  prototypeInfo: PrototypeCallout;
};

const Image = styled('img')(() => ({
  borderRadius: '1rem',
  width: '100%',
  height: 'auto',
  marginTop: '-4rem',
}));

export const PrototypeCard: React.FC<Props> = ({ prototypeInfo }) => {
  const image = useMediaUrl(prototypeInfo.PrototypeImage.data.attributes);
  return (
    <Paper variant="prototypeCallout">
      <Grid container>
        <Grid item xs={4}>
          <Image src={image} alt="Prototype" />
        </Grid>
        <Grid item xs={8} display="flex" alignItems="center" justifyContent="center">
          <Link href={prototypeInfo.PrototypeLink} sx={{ textDecoration: 'none' }}>
            <Typography variant="h4" component="p" mb={0} sx={{ color: 'common.white' }}>
              View the Prototype
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Paper>
  );
};
