import { gql, useQuery } from '@apollo/client';
import { FILE_FIELDS_FRAGMENT } from '../fragments';
import { CollectionResponse } from '../types';
import { ClientCards } from './types';

interface QueryResult {
  clientCards: CollectionResponse<ClientCards>;
}

export function useClientCards() {
  return useQuery<QueryResult>(query);
}

const query = gql`
  ${FILE_FIELDS_FRAGMENT}
  query GetClientCards {
    clientCards {
      data {
        id
        attributes {
          ClientName
          Industry
          HowWeHelped
          Logo {
            data {
              ...FileFields
            }
          }
        }
      }
    }
  }
`;
