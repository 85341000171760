import React from 'react';
import { SxProps, styled } from '@mui/system';

import { File } from '../../api';
import { useMediaUrl } from '../utils';

interface Props {
  file?: File | null;
  sx?: SxProps;
}

const Img = styled('img')();

export const Image: React.FC<Props> = ({ file, sx }) => {
  const src = useMediaUrl(file);

  if (!src) return null;

  return <Img src={src} sx={sx} alt={file?.alternativeText} />;
};
