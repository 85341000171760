import React from 'react';
import { Card, Box, CardContent, CardActions, Typography, styled, Link } from '@mui/material';
import { PrototypeCallout } from '../../api';
import { useMediaUrl } from '../../shared';

type Props = {
  prototype: PrototypeCallout;
};

const Image = styled('img')(() => ({
  borderRadius: '1rem',
  width: '100%',
  height: 'auto',
}));

export const LpPrototypeCard: React.FC<Props> = ({ prototype }) => {
  const image = useMediaUrl(prototype.PrototypeImage.data.attributes);
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Image src={image} alt={prototype.ClientName} />

      <Card sx={{ margin: '-4rem 1rem 0 1rem' }}>
        <CardContent sx={{ backgroundColor: 'common.white' }}>
          <Typography variant="h4" component="p">
            {prototype.ClientName}
          </Typography>
        </CardContent>
        <Link href={prototype.PrototypeLink} sx={{ textDecoration: 'none' }}>
          <CardActions>
            <Typography>View the prototype</Typography>
          </CardActions>
        </Link>
      </Card>
    </Box>
  );
};
