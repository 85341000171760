import { gql, useQuery } from '@apollo/client';
import { EntityItem } from '../types';
import { Service } from './types';
import { DYNAMICS_FORM_FRAGMENT, SEO_FIELDS_FRAGMENT } from '../fragments';

interface QueryResult {
  customWebPage: EntityItem<Service>;
}

export function useCustomWeb() {
  return useQuery<QueryResult>(query);
}

const query = gql`
  ${SEO_FIELDS_FRAGMENT}
  ${DYNAMICS_FORM_FRAGMENT}
  query GetCustomWeb {
    customWebPage {
      data {
        id
        attributes {
          PageCopy
          MetaData {
            ...SEOFields
          }
          Banner {
            Headline
            Subhead
          }
          DynamicsForm {
            data {
              ...DynamicsFormField
            }
          }
        }
      }
    }
  }
`;
