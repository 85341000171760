import React from 'react';
import { Typography, Container, Grid, Paper, Box, List } from '@mui/material';
import { AwardDate } from './AwardDate';
import { AwardListItem } from './AwardListItem';
import { RecentAwardsItem } from './RecentAwardsItem';
import { SortDirection, useAchievements, useFeaturedAchievements } from '../../../api';

export const AwardsSection: React.FC = () => {
  const achievementsQuery = useAchievements({
    sortBy: [{ field: 'year', direction: SortDirection.Desc }],
  });
  const featuredAchievementsQuery = useFeaturedAchievements();
  const recentAchievements = featuredAchievementsQuery.data?.achievements.data.filter(function (
    featuredAchievements
  ) {
    return featuredAchievements.attributes.featured;
  });
  if (!achievementsQuery.data) return null;

  return (
    <Paper variant="gradient2">
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={5} mb={1}>
            <Box
              sx={{
                backgroundColor: 'transparent.white',
                borderRadius: '1rem',
                boxShadow: '0px 8px 16px rgb(0 0 0 / 20%)',
              }}
              p={0}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="h4" component="p" p={2} m={0}>
                Recent Achievements
              </Typography>
              <Grid container sx={{ flex: '1 1 auto' }}>
                {recentAchievements?.map(x => (
                  <Grid item xs={6} key={x.id}>
                    <RecentAwardsItem featuredAchievement={x.attributes} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} mb={1}>
            <Box
              sx={{
                backgroundColor: 'transparent.white',
                borderRadius: '1rem',
                width: '100%',
                boxShadow: '0px 8px 16px rgb(0 0 0 / 20%)',
              }}
              p={2}
            >
              <Typography variant="h4" component="p">
                Past Achievements
              </Typography>

              {achievementsQuery.data.achievementYears.data.map(x => (
                <AwardDate date={x.attributes.year} key={x.id}>
                  <List sx={{ '& li': { display: 'list-item' } }}>
                    {x.attributes.achievements.data.map(y => (
                      <AwardListItem key={y.id} name={y.attributes.name} link={y.attributes.link} />
                    ))}
                  </List>
                </AwardDate>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};
