import { gql, useQuery } from '@apollo/client';

import { FILE_FIELDS_FRAGMENT } from '../fragments';
import { CollectionQueryOptions, CollectionResponse } from '../types';
import { formatSort } from '../utils';
import { AchievementYear } from './types';

interface QueryResult {
  achievementYears: CollectionResponse<AchievementYear>;
}

export function useAchievements(options: CollectionQueryOptions<AchievementYear>) {
  return useQuery<QueryResult>(query, {
    variables: {
      pagination: options.pagination,
      sort: formatSort(options.sortBy),
      publicationState: options.publicationState,
    },
  });
}

const query = gql`
  ${FILE_FIELDS_FRAGMENT}
  query GetAchievements(
    $pagination: PaginationArg
    $sort: [String]
    $publicationState: PublicationState
  ) {
    achievementYears(pagination: $pagination, sort: $sort, publicationState: $publicationState) {
      data {
        id
        attributes {
          year
          achievements {
            data {
              id
              attributes {
                name
                link
                featured
                image {
                  data {
                    ...FileFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
