import React, { useMemo } from 'react';
import { PageBanner, CTASection, Markdown } from '../shared/layout/components';
import { Container, Box, Grid, Typography, Paper, Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../icons';
import { PrototypeCard } from './components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { usePortfolioItem } from '../api/portfolio/portfolioItem.query';
import { LoadingIndicator, Meta, useMediaUrl } from '../shared';

export const PortfolioDetailPage: React.FC = () => {
  const { slug } = useParams();
  const portfolioItemQuery = usePortfolioItem(slug!);

  const project = useMemo(() => {
    if (!portfolioItemQuery.data?.portfolioItemPages.data.length) return null;

    return portfolioItemQuery.data.portfolioItemPages.data[0];
  }, [portfolioItemQuery]);
  const video = useMediaUrl(project?.attributes.PromoVideo.data.attributes);
  if (!portfolioItemQuery.data) return <LoadingIndicator />;

  return (
    <Box>
      <Meta seo={project?.attributes.SEO} />
      <PageBanner
        headline={project?.attributes.Banner.Headline}
        subCopy={project?.attributes.Banner.Subhead}
      />

      {project?.attributes.PromoVideo && (
        <Paper variant="VideoBanner">
          <video
            id="background-video"
            autoPlay
            loop
            muted
            poster="https://envative.com/media/ivtbwji4/envative-building-bg.jpg"
          >
            <source src={video} type="video/mp4" />
          </video>
        </Paper>
      )}

      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} lg={6} flexDirection="column">
            <Box>
              <Typography variant="h3" component="p" mb={2} sx={{ color: 'secondary.main' }}>
                <FontAwesomeIcon icon={icons.handshake as IconProp} size="xl" /> The Situation
              </Typography>
              <Markdown children={project?.attributes.SituationCopy!} />
            </Box>
            <Box p={3} mb={2} display="flex" justifyContent="center">
              <Divider sx={{ width: '100%' }} />
            </Box>

            <Box>
              <Typography variant="h3" component="p" mb={2} sx={{ color: 'secondary.main' }}>
                <FontAwesomeIcon icon={icons.caution as IconProp} size="xl" /> The Challenge
              </Typography>
              <Markdown children={project?.attributes.ChallengeCopy!} />
            </Box>
            <Box p={3} mb={2} display="flex" justifyContent="center">
              <Divider sx={{ width: '100%' }} />
            </Box>

            <Box>
              <Typography variant="h3" component="p" mb={2} sx={{ color: 'secondary.main' }}>
                <FontAwesomeIcon icon={icons.insights as IconProp} size="xl" /> The Solution
              </Typography>
              <Markdown children={project?.attributes.SolutionCopy!} />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper variant="statBlock">
              <Box p={2}>
                <Typography variant="h3" component="p" mb={2} sx={{ color: 'secondary.main' }}>
                  <FontAwesomeIcon icon={icons.insights as IconProp} size="xl" /> Project Statistics
                </Typography>
                <Typography variant="h5" component="p" mb={1}>
                  Project Deliverables:
                </Typography>
                <Box display="flex" flexDirection="row" alignItems="center">
                  {project?.attributes.ProjectDeliverables?.data.map(x => (
                    <Typography
                      key={x.id}
                      sx={{
                        backgroundColor: 'primary.main',
                        marginRight: '.5rem',
                        marginY: '1',
                        padding: '.25rem .5rem',
                        color: 'common.white',
                        fontSize: '.75rem!important',
                        fontWeight: '700',
                        borderRadius: '.25rem',
                      }}
                    >
                      {x.attributes.tag}
                    </Typography>
                  ))}
                </Box>
                <Typography variant="h5" component="p" mb={1}>
                  Technologies Used:
                </Typography>
                <Box display="flex" flexDirection="row" alignItems="center">
                  {project?.attributes.TechnologyUsed?.data.map(x => (
                    <Typography
                      key={x.id}
                      sx={{
                        backgroundColor: 'primary.main',
                        marginRight: '.5rem',
                        marginY: '1',
                        padding: '.25rem .5rem',
                        color: 'common.white',
                        fontSize: '.75rem!important',
                        fontWeight: '700',
                        borderRadius: '.25rem',
                      }}
                    >
                      {x.attributes.tag}
                    </Typography>
                  ))}
                </Box>
                <Typography variant="h5" component="p" mb={1}>
                  Project Timeline:
                </Typography>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography
                    sx={{
                      backgroundColor: 'secondary.main',
                      marginRight: '.5rem',
                      marginY: '1',
                      padding: '.25rem .5rem',
                      color: 'common.white',
                      fontSize: '.75rem!important',
                      fontWeight: '700',
                      borderRadius: '.25rem',
                    }}
                  >
                    {project?.attributes.ProjectTimeline}
                  </Typography>
                </Box>
                <Typography variant="h5" component="p" mb={1}>
                  Industry:
                </Typography>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography
                    sx={{
                      backgroundColor: 'secondary.main',
                      marginRight: '.5rem',
                      marginY: '1',
                      padding: '.25rem .5rem',
                      color: 'common.white',
                      fontSize: '.75rem!important',
                      fontWeight: '700',
                      borderRadius: '.25rem',
                    }}
                  >
                    {project?.attributes.IndustryTag?.data.attributes.tag}
                  </Typography>
                </Box>
              </Box>

              {project?.attributes.PrototypeCallout && (
                <PrototypeCard prototypeInfo={project.attributes.PrototypeCallout} />
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <CTASection
        title="We are ready to help you"
        img={icons.plane as IconProp}
        linkCopy="Contact Us"
        link="/contact-us"
      />
    </Box>
  );
};
