import { gql, useQuery } from '@apollo/client';
import { FILE_FIELDS_FRAGMENT, SEO_FIELDS_FRAGMENT } from '../fragments';
import { CollectionResponse } from '../types';
import { PortfolioItem } from './types';

interface QueryResult {
  portfolioItemPages: CollectionResponse<PortfolioItem>;
}

export function usePortfolioItem(slug: string) {
  return useQuery<QueryResult>(query, { variables: { slug } });
}

const query = gql`
  ${FILE_FIELDS_FRAGMENT}
  ${SEO_FIELDS_FRAGMENT}
  query GetPortfolioItem($slug: String) {
    portfolioItemPages(filters: { Slug: { eq: $slug } }) {
      data {
        id
        attributes {
          Banner {
            Headline
            Subhead
          }
          ClientName
          ProjectType
          HomepageSynopsis
          SituationCopy
          ChallengeCopy
          SolutionCopy
          Slug
          ProjectDeliverables {
            data {
              attributes {
                tag
              }
            }
          }
          TechnologyUsed {
            data {
              attributes {
                tag
              }
            }
          }
          IndustryTag {
            data {
              attributes {
                tag
              }
            }
          }
          ProjectTimeline
          PromoVideo {
            data {
              ...FileFields
            }
          }
          HompageImage {
            data {
              ...FileFields
            }
          }
          SEO {
            ...SEOFields
          }
          PortfolioGallery {
            id
            alt
            PortfolioImage {
              data {
                ...FileFields
              }
            }
          }
          PrototypeCallout {
            PrototypeLink
            PrototypeImage {
              data {
                ...FileFields
              }
            }
          }
        }
      }
    }
  }
`;
