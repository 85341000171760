export interface EntityItem<T> {
  data: Entity<T>;
}

export interface EntityCollection<T> {
  data: Array<Entity<T>>;
}

export interface CollectionResponse<T> extends EntityCollection<T> {
  meta: CollectionResponseMeta;
}

export type EntityID = string | number;

export interface Entity<T> {
  id: EntityID;
  attributes: T;
}

export interface CollectionResponseMeta {
  pagination: Pagination;
}

export interface Pagination {
  total: number;
  page: number;
  pageSize: number;
  pageCount: number;
}

export interface File {
  provider: string;
  url: string;
  alternativeText: string;
  name?: string;
  ext?: string;
  formats?: any;
}

interface PagePagination {
  page: number;
  pageSize: number;
}

interface OffsetPagination {
  start: number;
  limit: number;
}

export type PaginationArg = PagePagination | OffsetPagination;

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export interface Sort<T> {
  field: keyof T;
  direction: SortDirection;
}

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW',
}

export interface CollectionQueryOptions<T> {
  pagination?: PaginationArg;
  sortBy?: Array<Sort<T>>;
  publicationState?: PublicationState;
}
