import { gql, useQuery } from '@apollo/client';
import { SEO_FIELDS_FRAGMENT } from '../fragments';
import { EntityItem } from '../types';
import { CareerParent } from './types';

interface QueryResult {
  careersPage: EntityItem<CareerParent>;
}

export function useCareerParent() {
  return useQuery<QueryResult>(query);
}

const query = gql`
  ${SEO_FIELDS_FRAGMENT}
  query GetCareerParent {
    careersPage {
      data {
        id
        attributes {
          Banner {
            Headline
            Subhead
          }
          SEO {
            ...SEOFields
          }
          Copy
          careerList {
            data {
              id
              attributes {
                CareerName
                Description
                Slug
              }
            }
          }
          Video {
            YoutubeEmbedUrl
          }
        }
      }
    }
  }
`;
