import React from 'react';
import { CTASection, Markdown, PageBanner, YouTubeEmbed } from '../shared/layout/components';
import { Container, Box, Grid } from '@mui/material';
import { CareerBlock } from './components';
import { icons } from '../icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useCareerParent } from '../api';
import { LoadingIndicator, Meta } from '../shared';

export const CareersPage: React.FC = () => {
  const careerParentQuery = useCareerParent();
  if (!careerParentQuery.data) return <LoadingIndicator />;
  return (
    <Box>
      <Meta seo={careerParentQuery.data.careersPage.data.attributes.SEO} />
      <PageBanner
        headline={careerParentQuery.data.careersPage.data.attributes.Banner.Headline}
        subCopy={careerParentQuery.data.careersPage.data.attributes.Banner.Subhead}
      />
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6} flexDirection="column">
            <Markdown children={careerParentQuery.data.careersPage.data.attributes.Copy} />

            <YouTubeEmbed
              url={careerParentQuery.data.careersPage.data.attributes.Video.YoutubeEmbedUrl}
            />
          </Grid>
          <Grid item xs={12} lg={6} flexDirection="column">
            {careerParentQuery.data.careersPage.data.attributes.careerList?.data.map(x => (
              <Box key={x.id}>
                <CareerBlock careerItem={x.attributes} />
              </Box>
            ))}
          </Grid>
        </Grid>
      </Container>
      <CTASection
        title="We are ready to help you."
        linkCopy="contact"
        link="/contact-us"
        img={icons.plane as IconProp}
      />
    </Box>
  );
};
