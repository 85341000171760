import React from 'react';
import { Container, Grid, Typography, List, ListItem } from '@mui/material';

export const SummaryOfServices: React.FC = () => (
  <Container sx={{ paddingBottom: '3rem' }} maxWidth="xl">
    <Grid
      container
      spacing={2}
      sx={{
        display: 'flex',
        '& ul': {
          paddingLeft: '0px',
          listStyleType: 'none',
          width: '100%',
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
          '& li': {
            textAlign: 'left',
            backgroundColor: '#e3e3e3',
            marginBottom: '0.5rem',
            padding: '0.5rem',
            fontSize: '1rem',
            display: 'flex',
            flex: '1',
            width: '100%',
            justifyContent: 'center',
          },
        },
      }}
    >
      <Grid item xs={12} flexDirection="column">
        <Typography variant="h3">Summary of Services</Typography>
      </Grid>
      <Grid item md={4} flexDirection="column">
        <Typography variant="h4">Development</Typography>
        <List>
          <ListItem>Web Applications</ListItem>
          <ListItem>iOS/Android Application Development</ListItem>
          <ListItem>Machine Learning</ListItem>
          <ListItem>Hybrid Mobile Application Development</ListItem>
          <ListItem>Technical Architecture</ListItem>
          <ListItem>Data Modeling</ListItem>
          <ListItem>APIs</ListItem>
          <ListItem>Unit and Integration Testing Strategy</ListItem>
          <ListItem>Beacons and IoT</ListItem>
        </List>
      </Grid>
      <Grid item md={4} flexDirection="column">
        <Typography variant="h4">Design</Typography>
        <List>
          <ListItem>Design Thinking</ListItem>
          <ListItem>UX Analysis and Review</ListItem>
          <ListItem>Persona Development</ListItem>
          <ListItem>Full UX Design Process</ListItem>
          <ListItem>Wireframing</ListItem>
          <ListItem>Prototyping</ListItem>
          <ListItem>User Testing</ListItem>
          <ListItem>High-Resolution Mockups</ListItem>
        </List>
      </Grid>
      <Grid item md={4} flexDirection="column">
        <Typography variant="h4">Project Management</Typography>
        <List>
          <ListItem>Agile Methodology</ListItem>
          <ListItem>Scrum</ListItem>
          <ListItem>Sprint Review Process</ListItem>
          <ListItem>Budget Management</ListItem>
          <ListItem>Internal to External Coordination</ListItem>
          <ListItem>Status Updates</ListItem>
        </List>
      </Grid>
      <Grid item md={4} flexDirection="column">
        <Typography variant="h4">DEVOPS</Typography>
        <List>
          <ListItem>Distributed Logging</ListItem>
          <ListItem>System Monitoring</ListItem>
          <ListItem>Continuous Integration</ListItem>
          <ListItem>Cloud Services</ListItem>
          <ListItem>Automated Deployments</ListItem>
        </List>
      </Grid>
      <Grid item md={4} flexDirection="column">
        <Typography variant="h4">Continuous Improvement</Typography>
        <List>
          <ListItem>Analytics Analysis</ListItem>
          <ListItem>User Testing</ListItem>
          <ListItem>Performance Metrics</ListItem>
        </List>
      </Grid>
    </Grid>
  </Container>
);
