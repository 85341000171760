import React, { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, Box, Grid, Stack } from '@mui/material';

import { PageBanner } from '../shared/layout/components';
import { InsightArticleFilters, SortDirection, useInsightArticles } from '../api';
import { InsightFilters, InsightsCard } from './components';
import { MetaStatic, Pagination } from '../shared';
import metaImage from '../assets/images/envative-building-bg.jpg';

export const InsightsPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useMemo(() => {
    return {
      types: searchParams.getAll('types'),
      tags: searchParams.getAll('tags'),
    } as InsightArticleFilters;
  }, [searchParams]);

  const page = useMemo(() => {
    if (searchParams.has('page')) return parseInt(searchParams.get('page')!);

    return 1;
  }, [searchParams]);

  const articles = useInsightArticles(filters, {
    sortBy: [{ field: 'PublishDate', direction: SortDirection.Desc }],
    pagination: { page, pageSize: 10 },
  });

  const pagination = useMemo(() => articles.data?.insightArticles.meta.pagination, [articles]);

  const onFiltersChanged = useCallback(
    (filters: InsightArticleFilters) => {
      setSearchParams({ types: filters.types, tags: filters.tags }, { replace: true });
    },
    [setSearchParams]
  );

  const onPageChanged = useCallback(
    (page: number) => {
      setSearchParams(current => ({ ...current, page }));
    },
    [setSearchParams]
  );

  return (
    <Box>
      <MetaStatic
        title="Technology Insights"
        description="Insightful articles and blogs about software, business practices and current trends in technology"
        image={metaImage}
      />
      <PageBanner
        headline="Technology Insights"
        subCopy="If you're looking for insightful articles and blogs about software, business practices and current trends in technology, you've come to the right place. You'll also find news about Envative or clients we work with so you can get to know us a little better. And, if that's not enough, give us a call. We'd love to chat about what your needs are."
      />
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item md={3}>
            <InsightFilters filters={filters} onChange={onFiltersChanged} />
          </Grid>
          <Grid item md={9}>
            <Stack spacing={5}>
              <Grid container spacing={3}>
                {articles.data?.insightArticles.data.map(x => (
                  <Grid key={x.id} item lg={6} xs={12}>
                    <InsightsCard insight={x.attributes} />
                  </Grid>
                ))}
              </Grid>
              {pagination && <Pagination {...pagination} onPageChanged={onPageChanged} />}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
