import React from 'react';
import { Markdown, PageBanner } from '../shared/layout/components';
import { Container, Box, Grid, Paper, Typography } from '@mui/material';
import { LoadingIndicator, Meta } from '../shared';
import { useEstimation } from '../api';
import { ContactEmployeeBlock, DynamicsFormComponent } from './components';

export const FreeEstimationPage: React.FC = () => {
  const estimationQuery = useEstimation();
  if (!estimationQuery.data) return <LoadingIndicator />;
  return (
    <Box>
      <Meta seo={estimationQuery.data.estimationPage.data.attributes.SEO} />
      <PageBanner
        headline={estimationQuery.data.estimationPage.data.attributes.Banner.Headline}
        subCopy={estimationQuery.data.estimationPage.data.attributes.Banner.Subhead}
      />
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            lg={8}
            sx={{ '& iframe': { border: 0, minHeight: '500px' } }}
            flexDirection="column"
          >
            <Markdown children={estimationQuery.data.estimationPage.data.attributes.PageCopy} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Paper variant="roundedGrey">
              <Typography variant="h4">Free Estimation</Typography>
              <DynamicsFormComponent
                form={
                  estimationQuery.data.estimationPage.data.attributes.DynamicsForm.data.attributes
                }
              />
              <ContactEmployeeBlock
                contactEmployee={
                  estimationQuery.data.estimationPage.data.attributes.MeetingEmployee.data
                    .attributes
                }
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
