import React from 'react';
import './App.css';
import { Shell } from './shared/layout';
import { Providers } from './providers';
import { AppRoutes } from './AppRoutes';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import TagManager from 'react-gtm-module';
import ScrollToTop from './shared/components/ScrollToTop';

const tagManagerArgs = {
  gtmId: 'GTM-KC3587P',
};

TagManager.initialize(tagManagerArgs);

export const App: React.FC = () => {
  return (
    <Providers>
      <Shell>
        <ScrollToTop />
        <AppRoutes />
      </Shell>
    </Providers>
  );
};
