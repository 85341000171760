import React from 'react';
import { Typography, Box, Stack, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../../icons';
import { ProcessModal } from './ProcessModal';
import { ProcessSwiperShell } from './ProcessSwiperShell';
import { SwiperSlide } from 'swiper/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ButtonLink } from '../../components';
export const OurProcess: React.FC = () => {
  const matches = useMediaQuery('(min-width:1200px)');
  return (
    <Box textAlign="center">
      <Typography variant="h2" color="common.white" textAlign="center">
        Our Process, perfected
      </Typography>
      <Typography textAlign="center">
        Our process is what sets us apart. Our team has spent decades refining our project lifecycle
        phases to the point where we’re able to deliver the best solution—on time and within
        budget—every time. It’s a sprint-based, agile approach that eliminates the software
        development risks that are so abundant in the industry. It’s also a process that puts you in
        control of your project’s success.
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ display: matches ? 'flex' : 'none', margin: '2rem 0' }}
      >
        <Stack>
          <ProcessModal
            name="Consultation"
            description="Every successful project starts with a well-defined set of feature requirements. Many clients come to us with a vision of what they want, but when it comes to specifics, they want to hear options. We are consultants, first and foremost. Our team of experts will discuss your ideas with you and provide you with technical recommendations, feature suggestions, and the best options available to get you to your goals."
            img={icons.handshake as IconProp}
          />
          <Box p={3} display="flex" justifyContent="center">
            <FontAwesomeIcon icon={icons.down as IconProp} size="xl" />
          </Box>
          <ProcessModal
            name="Prototyping"
            description="To help you visualize and refine your feature requirements, our app design team will create for you a set of interactive prototypes that you can take to your key stakeholders, investors or internal user groups to get their input and feedback. This iterative process ensures buy-in from your constituents and allows us to deliver an accurate development budget and timeline based on the final, approved software design prototype. Let’s face it, no one likes surprises when it comes to budget overages and poor user interface. This step ensures there won’t be any."
            img={icons.columns as IconProp}
          />
          <Box p={3} display="flex" justifyContent="center">
            <FontAwesomeIcon icon={icons.down as IconProp} size="xl" />
          </Box>
          <ProcessModal
            name="Planning"
            description="Your PM will lead you through the process of setting sprint-review schedules, establishing communication flows and monitoring your budget throughout the project’s lifecycle. If you need a phased-release approach due to budget constraints or time-to-market goals, we'll work with you to identify your Minimum Viable Product (MVP) and product roadmap logistics."
            img={icons.list as IconProp}
          />
        </Stack>
        <Box>
          <FontAwesomeIcon icon={icons.forward as IconProp} size="2xl" />
        </Box>
        <Stack flexDirection="column" justifyContent="center">
          <Box>
            <Stack>
              <Stack direction="row" alignItems="center">
                <ProcessModal
                  name="Development"
                  description="Features are composed of user stories, which we then break down further to create well-defined development tasks for coding by our expert software engineers. Our technicians will work on architecture and coding as well as work with your internal development staff (if needed) for system integrations or API coordination."
                  img={icons.code as IconProp}
                />
                <Box p={3} display="flex" justifyContent="center">
                  <FontAwesomeIcon icon={icons.right as IconProp} size="xl" />
                </Box>

                <ProcessModal
                  name="testing"
                  description="Envative testing is a core component of each sprint cycle. We release code to a staging environment for your review only after our internal QA has identified any software issues and verified that they’ve been resolved. Any identified bugs are tracked and addressed as part of the current sprint."
                  img={icons.tasks as IconProp}
                />
              </Stack>
              <Stack direction="row" justifyContent="center" alignItems="center">
                <Box p={3} display="flex" justifyContent="center">
                  <FontAwesomeIcon icon={icons.up as IconProp} size="xl" />
                </Box>
                <Box p={2}>
                  <Typography m={0} textAlign="center" color="common.white">
                    SPRINT-BASED
                  </Typography>
                  <Typography m={0} textAlign="center" color="common.white">
                    AGILE DEVELOPMENT
                  </Typography>
                </Box>

                <Box p={3} display="flex" justifyContent="center">
                  <FontAwesomeIcon icon={icons.down as IconProp} size="xl" />
                </Box>
              </Stack>
              <Stack direction="row" alignItems="center">
                <ProcessModal
                  name="Sprint Cycle"
                  description="Based on the refined project roadmap, a cycle of development iteration planning begins; collaborative sprint review sessions commence; and project status reporting is delivered."
                  img={icons.calendar as IconProp}
                />
                <Box p={3} display="flex" justifyContent="center">
                  <FontAwesomeIcon icon={icons.left as IconProp} size="xl" />
                </Box>

                <ProcessModal
                  name="Evaluation"
                  description="Each feature set developed during a sprint cycle is released for your review and user acceptance testing. This keeps everyone engaged throughout development and ensures that there are no surprises at the end of the project. Any identified bugs are tracked and addressed as part of the next sprint."
                  img={icons.search as IconProp}
                />
              </Stack>
            </Stack>
          </Box>
        </Stack>
        <Stack>
          <FontAwesomeIcon icon={icons.forward as IconProp} size="2xl" />
        </Stack>
        <Stack>
          <ProcessModal
            name="Launch"
            description="You have signed off on your final user acceptance of the software and now it’s time for us to release your application to the production environment and/or mobile app stores. Woohoo! Your new app is live and available for public or operational use. Together, we’ve completed a thorough testing phase, however, it is not uncommon for software “bugs” to arise once in production when real-life interactions are applied. Don’t worry — you’ve entered our 30-day, post-launch warranty period during which we will quickly implement fixes at no charge."
            img={icons.rocket as IconProp}
          />
          <Box p={3} display="flex" justifyContent="center">
            <FontAwesomeIcon icon={icons.down as IconProp} size="xl" />
          </Box>
          <ProcessModal
            name="Maintenance"
            description="This is your insurance policy, meaning, we are with you for the long haul. The stability and well-being of your application demands the attention of monthly support to ensure that user experience and operations run smoothly no matter what. Don't let factors beyond your control (such as software or operating system upgrades, third-party integration changes, browser updates, etc.) disrupt your business."
            img={icons.shield as IconProp}
          />
        </Stack>
      </Stack>
      <Box style={{ display: matches ? 'none' : 'flex', maxWidth: '100%' }}>
        <ProcessSwiperShell>
          <SwiperSlide>
            <Stack>
              <ProcessModal
                name="Consultation"
                description="Every successful project starts with a well-defined set of feature requirements. Many clients come to us with a vision of what they want, but when it comes to specifics, they want to hear options. We are consultants, first and foremost. Our team of experts will discuss your ideas with you and provide you with technical recommendations, feature suggestions, and the best options available to get you to your goals."
                img={icons.handshake as IconProp}
              />
              <Box p={3} display="flex" justifyContent="center">
                <FontAwesomeIcon icon={icons.down as IconProp} size="xl" />
              </Box>
              <ProcessModal
                name="Prototyping"
                description="To help you visualize and refine your feature requirements, our app design team will create for you a set of interactive prototypes that you can take to your key stakeholders, investors or internal user groups to get their input and feedback. This iterative process ensures buy-in from your constituents and allows us to deliver an accurate development budget and timeline based on the final, approved software design prototype. Let’s face it, no one likes surprises when it comes to budget overages and poor user interface. This step ensures there won’t be any."
                img={icons.columns as IconProp}
              />
              <Box p={3} display="flex" justifyContent="center">
                <FontAwesomeIcon icon={icons.down as IconProp} size="xl" />
              </Box>
              <ProcessModal
                name="Planning"
                description="Your PM will lead you through the process of setting sprint-review schedules, establishing communication flows and monitoring your budget throughout the project’s lifecycle. If you need a phased-release approach due to budget constraints or time-to-market goals, we'll work with you to identify your Minimum Viable Product (MVP) and product roadmap logistics."
                img={icons.list as IconProp}
              />
            </Stack>
          </SwiperSlide>
          <SwiperSlide>
            <Stack flexDirection="column" justifyContent="center">
              <Box>
                <Stack>
                  <Stack direction="row" alignItems="center">
                    <ProcessModal
                      name="Development"
                      description="Features are composed of user stories, which we then break down further to create well-defined development tasks for coding by our expert software engineers. Our technicians will work on architecture and coding as well as work with your internal development staff (if needed) for system integrations or API coordination."
                      img={icons.code as IconProp}
                    />
                    <Box p={0} display="flex" justifyContent="center">
                      <FontAwesomeIcon icon={icons.right as IconProp} size="xl" />
                    </Box>

                    <ProcessModal
                      name="testing"
                      description="Envative testing is a core component of each sprint cycle. We release code to a staging environment for your review only after our internal QA has identified any software issues and verified that they’ve been resolved. Any identified bugs are tracked and addressed as part of the current sprint."
                      img={icons.tasks as IconProp}
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    <Box p={0} display="flex" justifyContent="center">
                      <FontAwesomeIcon icon={icons.up as IconProp} size="xl" />
                    </Box>
                    <Box p={2}>
                      <Typography m={0} textAlign="center" color="common.white">
                        SPRINT-BASED
                      </Typography>
                      <Typography m={0} textAlign="center" color="common.white">
                        AGILE DEVELOPMENT
                      </Typography>
                    </Box>

                    <Box p={0} display="flex" justifyContent="center">
                      <FontAwesomeIcon icon={icons.down as IconProp} size="xl" />
                    </Box>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <ProcessModal
                      name="Sprint Cycle"
                      description="Based on the refined project roadmap, a cycle of development iteration planning begins; collaborative sprint review sessions commence; and project status reporting is delivered."
                      img={icons.calendar as IconProp}
                    />
                    <Box p={0} display="flex" justifyContent="center">
                      <FontAwesomeIcon icon={icons.left as IconProp} size="xl" />
                    </Box>

                    <ProcessModal
                      name="Evaluation"
                      description="Each feature set developed during a sprint cycle is released for your review and user acceptance testing. This keeps everyone engaged throughout development and ensures that there are no surprises at the end of the project. Any identified bugs are tracked and addressed as part of the next sprint."
                      img={icons.search as IconProp}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </SwiperSlide>
          <SwiperSlide>
            <Stack>
              <ProcessModal
                name="Launch"
                description="You have signed off on your final user acceptance of the software and now it’s time for us to release your application to the production environment and/or mobile app stores. Woohoo! Your new app is live and available for public or operational use. Together, we’ve completed a thorough testing phase, however, it is not uncommon for software “bugs” to arise once in production when real-life interactions are applied. Don’t worry — you’ve entered our 30-day, post-launch warranty period during which we will quickly implement fixes at no charge."
                img={icons.rocket as IconProp}
              />
              <Box p={3} display="flex" justifyContent="center">
                <FontAwesomeIcon icon={icons.down as IconProp} size="xl" />
              </Box>
              <ProcessModal
                name="Maintenance"
                description="This is your insurance policy, meaning, we are with you for the long haul. The stability and well-being of your application demands the attention of monthly support to ensure that user experience and operations run smoothly no matter what. Don't let factors beyond your control (such as software or operating system upgrades, third-party integration changes, browser updates, etc.) disrupt your business."
                img={icons.shield as IconProp}
              />
            </Stack>
          </SwiperSlide>
        </ProcessSwiperShell>
      </Box>
      <Typography textAlign="center">
        Check out the details and you’ll see all the benefits of our process, including:
      </Typography>
      <Box sx={{ width: 'fit-content', margin: '3rem auto' }}>
        <Typography>
          <FontAwesomeIcon icon={icons.check as IconProp} size="xl" /> Knowledge continuity
        </Typography>
        <Typography>
          <FontAwesomeIcon icon={icons.check as IconProp} size="xl" /> Architectural governance
        </Typography>
        <Typography>
          <FontAwesomeIcon icon={icons.check as IconProp} size="xl" /> Technical risk mitigation
        </Typography>
        <Typography>
          <FontAwesomeIcon icon={icons.check as IconProp} size="xl" /> Flexibility to adapt to
          change
        </Typography>
      </Box>
      <ButtonLink to="/contact-us/envative-consultation">Lets get started</ButtonLink>
    </Box>
  );
};
