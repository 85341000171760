import React from 'react';
import { Typography, Paper, Box } from '@mui/material';

type Props = {
  headline?: string;
  subCopy?: string;
};

export const PageBanner: React.FC<Props> = ({ headline, subCopy }) => (
  <Paper variant="mainHeader">
    <Box sx={{ maxWidth: '1900px', padding: '12rem 0', marginLeft: 'auto', marginRight: 'auto' }}>
      <Box sx={{ padding: '0 1.5rem' }}>
        <Typography variant="h1">{headline}</Typography>
        {subCopy && <Typography>{subCopy}</Typography>}
      </Box>
    </Box>
  </Paper>
);
