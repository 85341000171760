import React from 'react';
import { Markdown, PageBanner } from '../shared/layout/components';
import { Container, Box, Grid, Paper, Typography, Link } from '@mui/material';
import { LoadingIndicator, Meta } from '../shared';
import { useContact } from '../api';
import { ContactEmployeeBlock, DynamicsFormComponent } from './components';

export const ContactPage: React.FC = () => {
  const contactQuery = useContact();
  if (!contactQuery.data) return <LoadingIndicator />;
  return (
    <Box>
      <Meta seo={contactQuery.data.contactPage.data.attributes.SEO} />
      <PageBanner
        headline={contactQuery.data.contactPage.data.attributes.Banner.Headline}
        subCopy={contactQuery.data.contactPage.data.attributes.Banner.Subhead}
      />
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Paper variant="roundedGrey">
              <Markdown children={contactQuery.data.contactPage.data.attributes.FormCopy} />
              <DynamicsFormComponent
                form={contactQuery.data.contactPage.data.attributes.DynamicsForm.data.attributes}
              />
              <ContactEmployeeBlock
                contactEmployee={
                  contactQuery.data.contactPage.data.attributes.MeetingEmployee.data.attributes
                }
              />
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{ '& iframe': { border: 0, minHeight: '500px' } }}
            flexDirection="column"
          >
            <Paper variant="roundedGrey" sx={{ padding: '0' }}>
              <Typography variant="h4" p={2} m={0}>
                Where we're located
              </Typography>
              <iframe
                data-gtm-yt-inspected-1_25="true"
                data-lf-yt-playback-inspected-jmvz8gkdlg982pod="true"
                height="100%"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2910.3096976468128!2d-77.60366678451817!3d43.161022979141286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d6b5ae7d59ac6b%3A0x9a9c569cb4d32d11!2sEnvative!5e0!3m2!1sen!2sus!4v1454706895297"
                title="google maps"
                width="100%"
              ></iframe>
              <Box sx={{ backgroundColor: 'secondary.main', color: 'common.white' }} p={2}>
                <Typography color="common.white" m={0}>
                  Envative Rochester
                </Typography>
              </Box>
              <Box p={2}>
                <Typography m={0}>550 E Main St, 2nd Floor</Typography>
                <Typography m={0}>Rochester, NY 14604</Typography>
                <Typography>
                  <Link href="tel:1.585.327.5640">T: 585.327.5640</Link>
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
