import React from 'react';
import { Box, Card, CardContent, Typography, styled } from '@mui/material';
import { ClientCards } from '../../api';
import { useMediaUrl } from '../../shared';
import { Markdown } from '../../shared/layout/components';

type Props = {
  clientCard: ClientCards;
};

const Image = styled('img')(() => ({
  position: 'relative',
  borderRadius: '1rem',
  maxWidth: '200px',
  maxHeight: '100px',
  margin: 'auto',
}));

export const ClientCard: React.FC<Props> = ({ clientCard }) => {
  const photoUrl = useMediaUrl(clientCard.Logo.data.attributes);
  return (
    <Card>
      <CardContent>
        <Image src={photoUrl} alt={clientCard.ClientName} />
        <Box
          sx={{
            borderBottom: 'solid thin',
            borderTop: 'solid thin',
            borderColor: 'grey.300',
            padding: '.5rem 0',
            marginBottom: '1rem',
          }}
        >
          <Typography m={0}>Industry: {clientCard.Industry}</Typography>
        </Box>
        <Typography>How we helped:</Typography>
        <Markdown children={clientCard.HowWeHelped} />
      </CardContent>
    </Card>
  );
};
