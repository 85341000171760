import React from 'react';
import { ListItem, ListItemAvatar, ListItemText, Avatar, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from '../../../components';
type Props = {
  children?: React.ReactNode;
  avatarIcon?: string;
  primary: string;
  img: IconProp;
  url: string;
};

export const ListItemObj: React.FC<Props> = ({ children, img, primary, url }) => {
  return (
    <ListItem component="div" sx={{ flexDirection: 'column', alignItems: 'start' }}>
      <Stack direction="row" justifyContent="center">
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'grey.700' }}>
            <FontAwesomeIcon icon={img} />
          </Avatar>
        </ListItemAvatar>
        <Link sx={{ textDecoration: 'none' }} to={url}>
          <ListItemText primary={primary} />
        </Link>
      </Stack>
      {children}
    </ListItem>
  );
};
