import React from 'react';
import { Box, Paper, styled } from '@mui/material';

type Props = {
  image: string;
  children?: React.ReactNode;
};

const Image = styled('img')(() => ({
  maxWidth: '100%',
  height: 'min-content',
}));

export const RobotFormBox: React.FC<Props> = ({ image, children }) => {
  return (
    <Paper variant="robotFormPaper">
      <Box className="RobotImage">
        <Image src={image} />
      </Box>
      {children}
    </Paper>
  );
};
