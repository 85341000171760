import React from 'react';
import { Grid, Stack, Typography, Paper, styled, Button } from '@mui/material';
import { ButtonLink } from '../../shared';

type Props = {
  image: string;
  author: string;
  bio: string;
  contactText?: string;
  contactLink: string;
};

const Image = styled('img')(() => ({
  borderRadius: '.5rem',
  maxWidth: '100%',
  height: 'min-content',
}));

export const AuthorCard: React.FC<Props> = ({ author, bio, contactText, image, contactLink }) => {
  return (
    <Paper variant="authorCard">
      <Grid container sx={{ marginBottom: '1rem' }}>
        <Grid item xs={3}>
          <Image src={image} alt={author} />
        </Grid>
        <Grid item xs={9}>
          <Stack p={2}>
            <Typography variant="h3">About the Author:</Typography>
            <Typography>{author}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Typography>{bio}</Typography>
      <Stack>
        <Button href={contactLink}>{contactText}</Button>
        <ButtonLink to="/insights">See all Insights</ButtonLink>
      </Stack>
    </Paper>
  );
};
