import { gql, useQuery } from '@apollo/client';
import { FILE_FIELDS_FRAGMENT, SEO_FIELDS_FRAGMENT } from '../fragments';
import { EntityItem } from '../types';
import { Home } from './types';

interface QueryResult {
  home: EntityItem<Home>;
}

export function useHome() {
  return useQuery<QueryResult>(query);
}

const query = gql`
  ${FILE_FIELDS_FRAGMENT}
  ${SEO_FIELDS_FRAGMENT}
  query GetHome {
    home {
      data {
        id
        attributes {
          PageBanner {
            Headline
            Subhead
            BannerButton {
              buttonCopy
              buttonLink
            }
          }
          SEO {
            ...SEOFields
          }
          RobotSlider {
            id
            Headline
            Robot1 {
              data {
                ...FileFields
              }
            }
            Robot2 {
              data {
                ...FileFields
              }
            }

            Bullet1
            Bullet2
            Bullet3
            Bullet4
            LinkText
            link
          }
          testimonials {
            data {
              id
              attributes {
                ClientName
                TestimonialCopy
              }
            }
          }
          portfolio_item_pages {
            data {
              id
              attributes {
                ClientName
                Slug
                Banner {
                  Headline
                }

                HomepageSynopsis
                HompageImage {
                  data {
                    ...FileFields
                  }
                }
              }
            }
          }
          insight_articles {
            data {
              id
              attributes {
                Title
                Description
                Slug
                image {
                  data {
                    ...FileFields
                  }
                }
                InsightType
                TimeToReadMin
                LinkCopy
                employee {
                  data {
                    attributes {
                      name
                      photo {
                        data {
                          ...FileFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          VideoSection {
            id
            Headline
            YoutubeEmbedUrl
            Copy
            buttonCopy
            ButtonLink
          }
        }
      }
    }
  }
`;
