import { gql, useQuery } from '@apollo/client';
import { FILE_FIELDS_FRAGMENT, SEO_FIELDS_FRAGMENT } from '../fragments';
import { EntityItem } from '../types';
import { About } from './types';

interface QueryResult {
  aboutUsPage: EntityItem<About>;
}

export function useAbout() {
  return useQuery<QueryResult>(query);
}

const query = gql`
  ${FILE_FIELDS_FRAGMENT}
  ${SEO_FIELDS_FRAGMENT}
  query GetAbout {
    aboutUsPage {
      data {
        id
        attributes {
          PageCopy
          Banner {
            Headline
            Subhead
            BannerButton {
              buttonCopy
              buttonLink
            }
          }
          PageImage {
            data {
              ...FileFields
            }
          }
          SEO {
            ...SEOFields
          }
          employees {
            data {
              attributes {
                name
                title
                photo {
                  data {
                    ...FileFields
                  }
                }
                reactionPhoto {
                  data {
                    ...FileFields
                  }
                }
                EmployeeContactInfo {
                  ContactLink
                  ContactText
                }
              }
            }
          }
        }
      }
    }
  }
`;
