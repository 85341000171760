import { gql, useQuery } from '@apollo/client';

import { FILE_FIELDS_FRAGMENT, PAGINATION_FIELDS_FRAGMENT } from '../fragments';
import { CollectionQueryOptions, CollectionResponse } from '../types';
import { formatSort } from '../utils';
import { InsightArticle, InsightArticleFilters } from './types';

interface QueryResult {
  insightArticles: CollectionResponse<InsightArticle>;
}

export function useInsightArticles(
  filters: InsightArticleFilters,
  options: CollectionQueryOptions<InsightArticle>
) {
  return useQuery<QueryResult>(query, {
    variables: {
      types: filters.types.length ? filters.types : undefined,
      tags: filters.tags.length ? filters.tags : undefined,
      pagination: options.pagination,
      sort: formatSort(options.sortBy),
      publicationState: options.publicationState,
    },
  });
}

const query = gql`
  ${FILE_FIELDS_FRAGMENT}
  ${PAGINATION_FIELDS_FRAGMENT}
  query SearchInsightArticles(
    $types: [String]
    $tags: [String]
    $pagination: PaginationArg
    $sort: [String]
    $publicationState: PublicationState
  ) {
    insightArticles(
      filters: { InsightType: { in: $types }, insight_tags: { tag: { in: $tags } } }
      pagination: $pagination
      sort: $sort
      publicationState: $publicationState
    ) {
      data {
        id
        attributes {
          Title
          Description
          InsightType
          insight_tags {
            data {
              id
              attributes {
                tag
              }
            }
          }
          image {
            data {
              ...FileFields
            }
          }
          TimeToReadMin
          PublishDate
          employee {
            data {
              id
              attributes {
                name
              }
            }
          }
          LinkCopy
          Slug
        }
      }
      meta {
        ...PaginationFields
      }
    }
  }
`;
