import { SEO } from '../components';
import { File, EntityItem, EntityCollection } from '../types';

export interface InsightArticle {
  id: string;
  Title: string;
  ArticleCopy: string;
  Description: string;
  InsightType: InsightType;
  TimeToReadMin: string;
  PublishDate: string;
  image: EntityItem<File>;
  LinkCopy: string;
  employee: EntityItem<InsightAuthor>;
  insight_tags: EntityCollection<InsightTag>;
  Slug: string;
  SEO: SEO;
}

export enum InsightType {
  Insight = 'Insight',
  News = 'News',
  TechTalk = 'TechTalk',
}

export interface InsightTag {
  tag: string;
}

export interface InsightAuthor {
  name: string;
  bio: string;
  photo: EntityItem<File>;
  AuthorContactInfo?: AuthorConact;
}

export interface AuthorConact {
  ContactText: string;
  ContactLink: string;
}

export interface InsightArticleFilters {
  types: Array<InsightType>;
  tags: Array<string>;
}
