import React from 'react';
import { CTASection, PageBanner } from '../shared/layout/components';
import { Container, Box, Grid } from '@mui/material';
import { PortfolioCard } from './components';
import { icons } from '../icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { usePortfolioParent } from '../api/portfolio/portfolio.query';
import { LoadingIndicator, Meta } from '../shared';
import { usePortfolioItems } from '../api/portfolio/allPortfolioItems.query';

export const PortfolioPage: React.FC = () => {
  const portfolioParentQuery = usePortfolioParent();
  const portfolioItemsQuery = usePortfolioItems();
  if (!portfolioParentQuery.data) return <LoadingIndicator />;
  return (
    <Box>
      <Meta seo={portfolioParentQuery.data.portfolioParentPage.data.attributes.SEO} />
      <PageBanner
        headline={portfolioParentQuery.data.portfolioParentPage.data.attributes.Banner.Headline}
        subCopy={portfolioParentQuery.data.portfolioParentPage.data.attributes.Banner.Subhead}
      />
      <Container maxWidth="xl">
        <Grid container spacing={4} mb={5} mt={5}>
          {portfolioItemsQuery.data?.portfolioItemPages.data.map(x => (
            <Grid item xs={12} lg={6} key={x.id}>
              <PortfolioCard portfolioItem={x.attributes} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <CTASection
        title="We are ready to help"
        linkCopy="Contact"
        img={icons.plane as IconProp}
        link="/contact-us"
      />
    </Box>
  );
};
