import React, { PropsWithChildren } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';

const auth = setContext((_, { headers, ...ctx }) => ({
  ...ctx,
  headers: {
    ...headers,
    authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
  },
}));
const retry = new RetryLink({ delay: { initial: 100 } });
const http = new BatchHttpLink({ uri: process.env.REACT_APP_STRAPI_API_ENDPOINT });

const apolloClient = new ApolloClient({
  link: auth.concat(retry).concat(http),
  cache: new InMemoryCache(),
});

export const ApolloClientProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
);