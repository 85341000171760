import React from 'react';
import { ListItem, Link } from '@mui/material';

type Props = {
  name: string;
  link?: string;
};

export const AwardListItem: React.FC<Props> = ({ name, link }) => (
  <ListItem>
    <Link href={link} sx={{ textDecoration: 'none' }} color="common.black">
      {name}
    </Link>
  </ListItem>
);
