import { gql, useQuery } from '@apollo/client';
import { FILE_FIELDS_FRAGMENT, SEO_FIELDS_FRAGMENT } from '../fragments';
import { EntityItem } from '../types';
import { PortfolioParent } from './types';

interface QueryResult {
  portfolioParentPage: EntityItem<PortfolioParent>;
}

export function usePortfolioParent() {
  return useQuery<QueryResult>(query);
}

const query = gql`
  ${FILE_FIELDS_FRAGMENT}
  ${SEO_FIELDS_FRAGMENT}
  query GetPortfolioParent {
    portfolioParentPage {
      data {
        id
        attributes {
          Banner {
            id
            Headline
            Subhead
          }
          SEO {
            ...SEOFields
          }
          featured_web_projects {
            data {
              id
              attributes {
                ClientName
                Slug
                ProjectType
                ProjectDeliverables {
                  data {
                    attributes {
                      tag
                    }
                  }
                }
                TechnologyUsed {
                  data {
                    attributes {
                      tag
                    }
                  }
                }
                HompageImage {
                  data {
                    ...FileFields
                  }
                }
                Banner {
                  Headline
                }
                HomepageSynopsis
              }
            }
          }
          featured_cms_projects {
            data {
              id
              attributes {
                ClientName
                Slug
                ProjectType
                HompageImage {
                  data {
                    ...FileFields
                  }
                }
                Banner {
                  Headline
                }
                HomepageSynopsis
              }
            }
          }
          featured_iot_projects {
            data {
              id
              attributes {
                ClientName
                Slug
                ProjectType
                HompageImage {
                  data {
                    ...FileFields
                  }
                }
                Banner {
                  Headline
                }
                HomepageSynopsis
              }
            }
          }
          featured_mobile_projects {
            data {
              id
              attributes {
                ClientName
                Slug
                ProjectType
                HompageImage {
                  data {
                    ...FileFields
                  }
                }
                Banner {
                  Headline
                }
                HomepageSynopsis
              }
            }
          }
          featured_prototyping_projects {
            data {
              id
              attributes {
                ClientName
                Slug
                ProjectType
                HompageImage {
                  data {
                    ...FileFields
                  }
                }
                Banner {
                  Headline
                }
                HomepageSynopsis
              }
            }
          }
        }
      }
    }
  }
`;
