import { gql, useQuery } from '@apollo/client';
import { FILE_FIELDS_FRAGMENT, SEO_FIELDS_FRAGMENT } from '../fragments';
import { CollectionResponse } from '../types';
import { LandingPage } from './types';

interface QueryResult {
  landingPages: CollectionResponse<LandingPage>;
}

export function useLandingPage(slug: string) {
  return useQuery<QueryResult>(query, { variables: { slug } });
}

const query = gql`
  ${FILE_FIELDS_FRAGMENT}
  ${SEO_FIELDS_FRAGMENT}
  query GetLandingPage($slug: String) {
    landingPages(filters: { Slug: { eq: $slug } }) {
      data {
        id
        attributes {
          SEO {
            ...SEOFields
          }
          Title
          Slug
          Banner {
            id
            Headline
            Subhead
          }
          PageCopy
          SideBarHeader
          employee {
            data {
              id
              attributes {
                name
                bio
                title
                EmployeeContactInfo {
                  id
                  ContactLink
                  ContactText
                }
                photo {
                  data {
                    ...FileFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
