import { gql, useQuery } from '@apollo/client';
import { CollectionResponse } from '../types';
import { InsightTag } from './types';

interface QueryResult {
  insightTags: CollectionResponse<InsightTag>;
}

export function useInsightTags() {
  return useQuery<QueryResult>(query);
}

const query = gql`
  query GetInsightTags {
    insightTags {
      data {
        id
        attributes {
          tag
        }
      }
    }
  }
`;
