import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  AwardsSection,
  CTASection,
  Markdown,
  OurProcess,
  PageBanner,
  SummaryOfServices,
} from '../shared/layout/components';
import { Container, Box, Grid, Typography, Paper } from '@mui/material';
import { Meta, LoadingIndicator } from '../shared';
import { icons } from '../icons';
import { useLandingPage } from '../api';
import { EmployeeCallout } from './components';

export const LandingPageShell: React.FC = () => {
  const { slug } = useParams();
  const landingPage = useLandingPage(slug!);

  const landingPageContent = useMemo(() => {
    if (!landingPage.data?.landingPages.data.length) return null;

    return landingPage.data.landingPages.data[0];
  }, [landingPage]);

  if (!landingPageContent) return <LoadingIndicator />;

  return (
    <Box>
      <Meta seo={landingPageContent.attributes.SEO} />
      <PageBanner
        headline={landingPageContent.attributes.Banner.Headline}
        subCopy={landingPageContent.attributes.Banner.Subhead}
      />
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} md={8} flexDirection="column">
            <Markdown children={landingPageContent.attributes.PageCopy} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper variant="authorCard">
              <Typography variant="h3">{landingPageContent.attributes.SideBarHeader}</Typography>
              <EmployeeCallout employee={landingPageContent.attributes.employee.data.attributes} />
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Paper variant="gradient2">
        <Container>
          <OurProcess />
        </Container>
      </Paper>
      <SummaryOfServices />
      <AwardsSection />
      <CTASection
        title="We are ready to help you"
        img={icons.plane}
        link="/contact-us"
        linkCopy="Contact Us"
      />
    </Box>
  );
};
