import { gql } from '@apollo/client';
import { FILE_FIELDS_FRAGMENT } from './file-fields';

export const MEETING_EMPLOYEE_FRAGMENT = gql`
  ${FILE_FIELDS_FRAGMENT}
  fragment MeetingEmployeeField on EmployeeEntity {
    attributes {
      name
      title
      bio
      photo {
        data {
          ...FileFields
        }
      }
      EmployeeContactInfo {
        ContactLink
        ContactText
      }
    }
  }
`;
