import React from 'react';
import { Typography, Container, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ButtonLink } from '../../components';

type Props = {
  title: string;
  linkCopy: string;
  link: string;
  img: IconProp;
};

export const CTASection: React.FC<Props> = ({ title, linkCopy, img, link }) => (
  <Container sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
    <Stack p={2}>
      <Typography variant="h2" sx={{ color: 'primary.main' }} textAlign="center">
        {title}
      </Typography>
    </Stack>
    <ButtonLink to={link}>
      <FontAwesomeIcon icon={img} /> <Typography>{linkCopy}</Typography>
    </ButtonLink>
  </Container>
);
