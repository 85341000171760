import React from 'react';
import { Typography, Container, Grid, Box, Paper, Stack, styled, Link } from '@mui/material';
import {
  AwardsSection,
  Markdown,
  PageBanner,
  SummaryOfServices,
} from '../shared/layout/components';
import { useAbout } from '../api';
import { TeamCard } from './components';
import { ButtonLink, LoadingIndicator, Meta } from '../shared';
import CraigImage from '../assets/images/clamb.jpg';

const EmployeePhoto = styled('img')(() => ({
  borderRadius: '.5rem',
  height: 'min-content',
  maxWidth: '100%',
}));

export const AboutPage: React.FC = () => {
  const aboutQuery = useAbout();
  if (!aboutQuery.data) return <LoadingIndicator />;

  return (
    <Box>
      <Meta seo={aboutQuery.data.aboutUsPage.data.attributes.SEO} />
      <PageBanner
        headline={aboutQuery.data.aboutUsPage.data.attributes.Banner.Headline}
        subCopy={aboutQuery.data.aboutUsPage.data.attributes.Banner.Subhead}
      />
      <Container maxWidth="xl">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} lg={8} flexDirection="column">
            <Box py={6}>
              <Markdown children={aboutQuery.data.aboutUsPage.data.attributes.PageCopy} />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4} flexDirection="column">
            <Paper variant="authorCard">
              <Grid container sx={{ marginBottom: '1rem' }}>
                <Grid item xs={12}>
                  <Stack direction="column">
                    <Typography variant="h3" mb={0}>
                      IN NEED OF A SMARTER SOLUTION?
                    </Typography>
                    <Typography>Meet with Craig.</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <EmployeePhoto src={CraigImage} alt="Craig Lamb" />
                </Grid>
                <Grid item xs={9}>
                  <Stack p={2}>
                    <Typography variant="h4">Craig Lamb</Typography>
                    <Typography>Managing Partner</Typography>
                    <Link href="https://drift.me/craiglamb" variant="body1">
                      View my calendar
                    </Link>
                  </Stack>
                </Grid>
              </Grid>
              <Stack>
                <ButtonLink to="/contact-us/envative-consultation">
                  Schedule a free consultation
                </ButtonLink>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <SummaryOfServices />
      <AwardsSection />
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} flexDirection="column" alignItems="center">
            <Typography variant="h2" color="primary.main">
              Leadership Team
            </Typography>
          </Grid>
          {aboutQuery.data?.aboutUsPage.data.attributes.employees.data.map(x => (
            <Grid key={x.id} item xs={6} lg={3}>
              <TeamCard employee={x.attributes}></TeamCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
