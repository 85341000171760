import React from 'react';
import { ListItem, ListItemText } from '@mui/material';
import { Link } from '../../../components';

type Props = {
  secondary: string;
  url: string;
};

export const SubListItem: React.FC<Props> = ({ secondary, url }) => {
  return (
    <ListItem component="div">
      <Link to={url} sx={{ textDecoration: 'none' }}>
        <ListItemText secondary={secondary} />
      </Link>
    </ListItem>
  );
};
