import React from 'react';
import { Grid, Link, styled, Typography } from '@mui/material';

type Props = {
  image: string;
  fname: string;
  lname: string;
  title: string;
  linkcopy: string;
  link: string;
};

const Image = styled('img')(() => ({
  maxWidth: '100%',
  height: 'min-content',
}));

export const ScheduleMeetingBox: React.FC<Props> = ({
  image,
  fname,
  lname,
  title,
  linkcopy,
  link,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" component="p" mb={0}>
          Or meet with {fname}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Image src={image} alt={fname} />
      </Grid>
      <Grid item xs={8} flexDirection="column">
        <Typography variant="h4" component="p">
          {fname} {lname}
        </Typography>
        <Typography>{title}</Typography>
        <Typography>
          <Link href={link}>{linkcopy}</Link>
        </Typography>
      </Grid>
    </Grid>
  );
};
