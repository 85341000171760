import { Box, Button } from '@mui/material';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper } from 'swiper/react';

type Props = {
  children?: React.ReactNode;
};

export const InsightSwiperShell: React.FC<Props> = ({ children }) => {
  return (
    <Box
      textAlign="center"
      sx={{
        minHeight: '25vh',
        marginY: '4rem',
        '& .swiper': {
          minHeight: '25vh',
          paddingBottom: '3.75rem',

          '& .swiper-slide': {
            minHeight: '25vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          '& .swiper-pagination': {
            bottom: '1rem',
          },

          '& .swiper-pagination-bullet': {
            width: '1rem !important',
            height: '1rem !important',
            display: 'inline-block',
            borderRadius: '50% !important',
            background: 'common.black',
            opacity: '.5',
          },
          '& .swiper-pagination-bullet-active': {
            backgroundColor: 'common.black',
            opacity: '1',
          },
          '& .swiper-button-next': {
            top: '0',
            height: '100%',

            '&:after': {
              color: 'secondary.dark',
              opacity: '0.125',
              marginLeft: '-1rem',
              transition: 'margin-left 0.5s ease-in-out, opacity 0.5s ease-in-out',
            },

            '&: hover': {
              '&:after': {
                opacity: '1',
                marginLeft: '0rem',
              },
            },
          },
          '& .swiper-button-prev': {
            top: '0',
            height: '100%',

            '&:after': {
              color: 'secondary.dark',
              opacity: '0.125',
              marginRight: '-1rem',
              transition: 'margin-right 0.5s ease-in-out, opacity 0.5s ease-in-out',
            },

            '&: hover': {
              '&:after': {
                opacity: '1',
                marginRight: '0rem',
              },
            },
          },
        },
      }}
    >
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        breakpoints={{
          // when window width is >= 640px
          320: {
            slidesPerView: 1,
          },
          // when window width is >= 768px
          900: {
            slidesPerView: 2,
          },

          2000: {
            slidesPerView: 3,
          },
        }}
        navigation
        pagination={{ clickable: true }}
        onSwiper={(swiper: any) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
        {children}
      </Swiper>
      <Button href="/insights" sx={{ margin: '0 auto' }}>
        View all our insights
      </Button>
    </Box>
  );
};
