import React from 'react';
import {
  AppBar,
  Stack,
  styled,
  Typography,
  Button,
  useScrollTrigger,
  Paper,
  Drawer,
} from '@mui/material';
import LogoImage from '../../../assets/logos/envative-logo-main-25.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../../icons';
import { Container } from '@mui/system';
import { Menu } from './menu';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ButtonLink, Link } from '../../components';

const Logo = styled('img')(({ theme }) => ({
  display: 'inline-block',

  height: 'auto',

  [theme.breakpoints.up('xs')]: {
    maxWidth: '10rem!important',
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: '15rem!important',
  },
}));

type Anchor = 'top';

export const AppHeader: React.FC = () => {
  const isScrolled = useScrollTrigger({ threshold: 0, disableHysteresis: true });
  const anchor = 'top';
  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  return (
    <>
      <AppBar position="fixed" color="transparent" elevation={0} sx={{ zIndex: '100' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            textTransform: 'uppercase',
            fontSize: '.75rem',
            fontWeight: '700',
            padding: '1rem',
            zIndex: '1001',
            backgroundColor: isScrolled ? 'rgb(255 255 255 / 90%)' : 'rgb(255 255 255 / 0%)',
            boxShadow: isScrolled ? '0px 8px 10px -5px rgba(0,0,0,0.2)' : 'none',
            transition: 'all 0.5s ease-in-out',
          }}
        >
          <Link to="/">
            <Logo
              src={LogoImage}
              alt="Envative: Smarter Software Solutions. Web, Mobile, and IoT"
              className="mainLogo"
            />
          </Link>

          <Stack direction="row">
            <ButtonLink to="contact-us/envative-consultation">
              <FontAwesomeIcon icon={icons.calendar as IconProp} />
              <Typography sx={{ display: { xs: 'none', lg: 'block' } }}>
                Free Consultation
              </Typography>
            </ButtonLink>
            <ButtonLink to="/contact-us">
              <FontAwesomeIcon icon={icons.phone as IconProp} />
              <Typography sx={{ display: { xs: 'none', lg: 'block' } }}>585.327.5640</Typography>
            </ButtonLink>
            <Button onClick={toggleDrawer(anchor, true)}>
              <FontAwesomeIcon icon={icons.menu as IconProp} />
            </Button>
          </Stack>
        </Stack>
        <Drawer
          variant="temporary"
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          sx={{ boxShadow: 'none' }}
          elevation={0}
          ModalProps={{ disableScrollLock: true }}
          transitionDuration={400}
        >
          <Paper
            variant="menuContainer"
            role="button"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            sx={{
              top: {
                xs: isScrolled ? 'calc(-100vh + 90px)' : '-100vh',
                md: isScrolled ? 'calc(-100vh + 120px)' : '-100vh',
              },
              transition: 'all 0.5s ease-in-out',
            }}
          >
            <Container maxWidth="xl">
              <Menu />
            </Container>
          </Paper>
        </Drawer>
      </AppBar>
    </>
  );
};
