import React from 'react';
import { PageBanner } from '../shared/layout/components';
import { Container, Box, Typography, Link } from '@mui/material';
import { MetaStatic } from '../shared';
import metaImage from '../assets/images/envative-building-bg.jpg';

export const EstimationThankYouPage: React.FC = () => {
  return (
    <Box>
      <MetaStatic
        title="Thank you!"
        description="Thank you for submitting your request for an esitmate"
        image={metaImage}
      />
      <PageBanner headline="We've received your estimation request" />
      <Container maxWidth="lg">
        <Box p={5}>
          <Typography>
            One of our fearless leaders, will be reaching out to you soon about your estimation.
          </Typography>
          <Typography>The Envative team looks forward to talking to you</Typography>
          <Typography>
            <Link href="/">Take me home</Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
