import { gql } from '@apollo/client';
import { FILE_FIELDS_FRAGMENT } from './file-fields';

export const SEO_FIELDS_FRAGMENT = gql`
  ${FILE_FIELDS_FRAGMENT}
  fragment SEOFields on ComponentSharedSeo {
    id
    metaTitle
    metaDescription
    metaImage {
      data {
        ...FileFields
      }
    }
    metaSocial {
      id
      socialNetwork
      title
      description
      image {
        data {
          ...FileFields
        }
      }
    }
    keywords
    metaRobots
    structuredData
    metaViewport
    canonicalURL
  }
`;
