import React from 'react';
import { Card, Box, Paper, CardContent, CardActions, Typography, styled } from '@mui/material';
import { Link, useMediaUrl } from '../../shared';
import { PortfolioItem } from '../../api';

type Props = {
  portfolioItem: PortfolioItem;
};

const Image = styled('img')(() => ({
  borderRadius: '1rem',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '500px',
  marginTop: '-4rem',
}));

export const PortfolioCard: React.FC<Props> = ({ portfolioItem }) => {
  const image = useMediaUrl(portfolioItem.HompageImage.data.attributes);
  return (
    <Box display="flex" flexDirection="column" marginBottom="5rem">
      <Paper variant="cardBG1">
        <Image src={image} alt="icon" />
      </Paper>

      <Card sx={{ margin: '-4rem 1rem 0 1rem' }}>
        <CardContent sx={{ backgroundColor: 'common.white', padding: '0' }}>
          <Box p={2} display="flex" flexDirection="column" flex="1">
            <Link to={'/portfolio/' + portfolioItem.Slug} sx={{ textDecoration: 'none' }}>
              <Typography variant="h3" sx={{ color: 'secondary.main' }}>
                {portfolioItem.Banner.Headline}
              </Typography>
            </Link>
            <Typography>{portfolioItem.HomepageSynopsis}</Typography>
          </Box>

          <Box
            sx={{ backgroundColor: 'grey.200', borderTop: 'solid thin', borderColor: 'grey.300' }}
            p={2}
          >
            <Typography variant="h5" component="p" mb={1}>
              Project Deliverables:
            </Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
              {portfolioItem.ProjectDeliverables?.data.map(x => (
                <Typography
                  key={x.id}
                  sx={{
                    backgroundColor: 'primary.main',
                    marginRight: '.5rem',
                    marginY: '1',
                    padding: '.25rem .5rem',
                    color: 'common.white',
                    fontSize: '.75rem!important',
                    fontWeight: '700',
                    borderRadius: '.25rem',
                  }}
                >
                  {x.attributes.tag}
                </Typography>
              ))}
            </Box>
            <Typography variant="h5" component="p" mb={1}>
              Technologies Used:
            </Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
              {portfolioItem.TechnologyUsed?.data.map(x => (
                <Typography
                  key={x.id}
                  sx={{
                    backgroundColor: 'primary.main',
                    marginRight: '.5rem',
                    marginY: '1',
                    padding: '.25rem .5rem',
                    color: 'common.white',
                    fontSize: '.75rem!important',
                    fontWeight: '700',
                    borderRadius: '.25rem',
                  }}
                >
                  {x.attributes.tag}
                </Typography>
              ))}
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          <Link to={'/portfolio/' + portfolioItem.Slug} sx={{ textDecoration: 'none' }}>
            <Typography>See how we helped {portfolioItem.ClientName}</Typography>
          </Link>
        </CardActions>
      </Card>
    </Box>
  );
};
