import React from 'react';
import { Paper, Typography } from '@mui/material';
import { CareerItem } from '../../api';
import { Link } from '../../shared';

type Props = {
  careerItem: CareerItem;
};

export const CareerBlock: React.FC<Props> = ({ careerItem }) => {
  return (
    <Paper variant="roundedGrey">
      <Typography variant="h4" component="p">
        {careerItem.CareerName}
      </Typography>
      <Typography>{careerItem.Description}</Typography>
      <Link to={careerItem.Slug}>View {careerItem.CareerName}</Link>
    </Paper>
  );
};
