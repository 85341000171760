import React, { useCallback } from 'react';
import { Button, Stack, Typography } from '@mui/material';

interface Props {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;

  onPageChanged: (page: number) => void;
}

export const Pagination: React.FC<Props> = ({ page, pageCount, onPageChanged }) => {
  const changePage = useCallback(
    (page: number) => {
      onPageChanged(page);
      window.scrollTo(0, 0);
    },
    [onPageChanged]
  );

  return (
    <Stack direction="row" alignItems="center" justifyContent="center" py={3}>
      <Button
        type="button"
        variant="pagButton"
        disabled={page === 1}
        onClick={() => changePage(page - 1)}
      >
        Prev
      </Button>
      <Typography sx={{ marginBottom: '0' }}>
        Page {page} of {pageCount}
      </Typography>
      <Button
        type="button"
        variant="pagButton"
        disabled={page === pageCount}
        onClick={() => changePage(page + 1)}
      >
        Next
      </Button>
    </Stack>
  );
};
