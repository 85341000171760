import { gql, useQuery } from '@apollo/client';
import { TestimonialsPage } from './types';
import { EntityItem } from '../types';
import { SEO_FIELDS_FRAGMENT } from '../fragments';

interface QueryResult {
  testimonialsPage: EntityItem<TestimonialsPage>;
}

export function useTestimonialPage() {
  return useQuery<QueryResult>(query);
}

const query = gql`
  ${SEO_FIELDS_FRAGMENT}
  query GetTestimonialsPage {
    testimonialsPage {
      data {
        id
        attributes {
          Banner {
            Headline
            Subhead
          }
          VideoTestimonials {
            YoutubeEmbedUrl
          }
          SEO {
            ...SEOFields
          }
        }
      }
    }
  }
`;
