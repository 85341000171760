import React from 'react';
import {
  CTASection,
  Markdown,
  OurProcess,
  PageBanner,
  ScheduleMeetingBox,
} from '../shared/layout/components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Container, Box, Paper, Grid, Typography } from '@mui/material';
import { icons } from '../icons';
import { useWebsites } from '../api/services';
import { RobotFormBox } from './components';
import robotSD2 from '../assets/images/robot-website-hover.png';
import craig from '../assets/images/clamb3.png';
import { LoadingIndicator, Meta } from '../shared/components';
import { DynamicsFormComponent } from '../contact/components';

export const WebsitesPage: React.FC = () => {
  const cmsQuery = useWebsites();
  if (!cmsQuery.data) return <LoadingIndicator />;
  return (
    <Box>
      <Meta seo={cmsQuery.data.websitesPage.data.attributes.MetaData} />
      <PageBanner
        headline={cmsQuery.data.websitesPage.data.attributes.Banner.Headline}
        subCopy={cmsQuery.data.websitesPage.data.attributes.Banner.Subhead}
      />
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6} flexDirection="column">
            <Markdown children={cmsQuery.data.websitesPage.data.attributes.PageCopy} />
          </Grid>
          <Grid item xs={12} md={6}>
            <RobotFormBox image={robotSD2}>
              <Typography variant="h3">Free Consultation</Typography>
              <DynamicsFormComponent
                form={cmsQuery.data.websitesPage.data.attributes.DynamicsForm.data.attributes}
              />
              <ScheduleMeetingBox
                image={craig}
                fname="Craig"
                lname="Lamb"
                title="Managing Partner"
                link="https://drift.me/craiglamb"
                linkcopy="View My Calendar"
              />
            </RobotFormBox>
          </Grid>
        </Grid>
      </Container>
      <Paper variant="gradient2">
        <Container>
          <OurProcess />
        </Container>
      </Paper>
      <CTASection
        title="We are ready to help you"
        linkCopy="Contact Us"
        link="/contact-us"
        img={icons.plane as IconProp}
      />
    </Box>
  );
};
