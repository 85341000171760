import React from 'react';
import { CTASection, PageBanner } from '../shared/layout/components';
import { Container, Box, Typography, List, ListItem } from '@mui/material';
import { icons } from '../icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const PrivacyPolicy: React.FC = () => {
  return (
    <Box>
      <PageBanner headline="Statement of Privacy" />
      <Container maxWidth="lg">
        <Typography variant="h2" component="h4">
          RESPECTING AND PROTECTING YOUR PRIVACY
        </Typography>
        <Typography>
          Your right to privacy should be respected and protected at every opportunity. At Envative
          that is our commitment to you — and to every business customer we serve. As part of our
          pledge, we are providing this notice to explain our online practices, the individual
          choices you can make regarding your personal information and how your
          personally-identifiable information is collected and used.
        </Typography>

        <Typography variant="h2" component="h4">
          PERSONALLY-IDENTIFIABLE INFORMATION
        </Typography>
        <Typography>
          Typically, a visit to the Envative website does not require you to disclose any
          information about yourself. There may be occasions, however, when Envative may require
          details about you, such as your name, address, and email address. Of course, we can only
          collect this information when you voluntarily submit it to us as part of a survey, contact
          request form or when you register for downloadable content, special events, or other
          communications. For example, the Envative website may offer you opportunities to receive
          informative content and other communications of interest. If you register to receive these
          communications, you may be asked to provide your email address, the topics in which you
          are interested, and other demographic information.
        </Typography>

        <Typography variant="h2" component="h4">
          HOW WE USE PERSONALLY-IDENTIFIABLE INFORMATION
        </Typography>
        <Typography>
          If you decide to provide us with personally-identifiable information via the Envative
          website, Envative will use your personally-identifiable information to fulfill content or
          other communications requests. We may also use the personally-identifiable information you
          provide to:
        </Typography>
        <List>
          <ListItem>
            Simplify your access to the website so you will not have to enter personal information
            on follow-up visits.
          </ListItem>

          <ListItem>Customize the website content that is most relevant to you.</ListItem>
          <ListItem>
            Notify you about important news concerning new products and services, product updates,
            technical support issues, and special offers we think you may be interested in.
          </ListItem>
        </List>
        <Typography>
          It is Envative’s explicit policy not to sell, rent, or lease any personally-identifiable
          information. The only such information we will share with our partners is the
          personally-identifiable information you input voluntarily. However, Envative reserves the
          right to disclose any and all pertinent website visitor information to law enforcement or
          other government officials as we, in our sole discretion, believe necessary or
          appropriate.
        </Typography>

        <Typography variant="h2" component="h4">
          NON-PERSONALLY-IDENTIFIABLE INFORMATION
        </Typography>
        <Typography>
          In the process of administering the Envative website, we maintain and track usage through
          Web server logs. Web server logs provide such information as the types of browsers
          accessing the website, what pages receive high traffic, and the times of day our servers
          experience significant load. We use this information to improve the content and navigation
          features of the Envative website. Anonymized or aggregated forms of this information may
          be used to identify future features and functions to develop for the website and provide
          better customer service. We do analyze some of our Envative website services, [such as
          Demos and Downloads], at the individual level in order to improve the quality of those
          offerings and to better tailor our marketing to our customer needs. In general, Web
          analysis data is not used by Envative to identify you personally.
        </Typography>
        <Typography>
          Please note that some electronic communications at the Envative website may record
          anonymous data related to the response. Analysis of this data is solely for the purpose of
          improving the content of the ENVATIVE website and customer service.
        </Typography>

        <Typography variant="h2" component="h4">
          KEEPING INFORMATION SECURE
        </Typography>
        <Typography>
          To secure your important personally-identifiable information, and your peace of mind,
          Envative has put reasonable physical, electronic, and managerial procedures in place to
          safeguard the personal information we collect on the Envative website. Such steps allow us
          to prevent unauthorized access or disclosure, maintain data accuracy, and ensure the
          appropriate use of the personally-identifiable information you provide.
        </Typography>

        <Typography variant="h2" component="h4">
          EXTERNAL LINKS
        </Typography>
        <Typography>
          As a convenience to you, links to third-party websites are available from the Envative
          website. If you decide to use these links, you will leave the Envative website. Please be
          aware that Envative has not reviewed these third-party websites and does not control them.
          In addition, Envative is not responsible for the content of these sites or their privacy
          policies. Furthermore, Envative does not endorse or make any representations about
          third-party websites or any information, software or other products or materials found
          there, or any results that may be obtained from using them. [As always, your decision to
          access any of the third-party websites linked to the Envative website is done so at your
          own risk.]
        </Typography>

        <Typography variant="h2" component="h4">
          COOKIES
        </Typography>
        <Typography>
          A technology called "cookies" is commonly used to provide tailored information from a
          website. A cookie is basically an element of data that a website can send to your browser,
          which may then store it on your system. Some pages at the Envative website use cookies or
          other technologies so that Envative can better serve you when you return to our website.
          We do not use cookies or similar technology to cross-relate personal information about our
          visitors. You can set your browser to notify you when you receive a cookie, giving you the
          chance to decide whether to accept it. For more information on cookies, please refer to
          user information provided with your Web browser.
        </Typography>

        <Typography variant="h2" component="h4">
          OPT-OUT
        </Typography>
        <Typography>
          Envative offers you the opportunity to "opt-out" of having your personally-identifiable
          information used for purposes not directly related to your access to and experience at our
          website at the point where we ask for the information. For example, we provide an
          "opt-out" mechanism if you do not wish to receive unsolicited email marketing materials
          from us and we will take reasonable steps to honor such email opt-out requests.
        </Typography>

        <Typography variant="h2" component="h4">
          Acceptance of the Privacy Policy
        </Typography>
        <Typography>
          By using the Envative website, you consent to the terms contained in this Privacy Policy
          and to any subsequently posted changes to the Privacy Policy. Envative may update or
          change this Privacy Policy and will post any changes on this page from time to time.
        </Typography>
      </Container>
      <CTASection
        title="We are ready to help you"
        linkCopy="Contact Us"
        link="/contact-us"
        img={icons.plane as IconProp}
      />
    </Box>
  );
};
