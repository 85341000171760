import { gql, useQuery } from '@apollo/client';
import { FILE_FIELDS_FRAGMENT } from '../fragments';
import { EntityItem } from '../types';
import { Footer } from './types';

interface QueryResult {
  footer: EntityItem<Footer>;
}

export function useFooter() {
  return useQuery<QueryResult>(query);
}

const query = gql`
  ${FILE_FIELDS_FRAGMENT}
  query GetFooter {
    footer {
      data {
        id
        attributes {
          Address
          Tagline
          Badges {
            id
            Name
            Link
            Image {
              data {
                ...FileFields
              }
            }
          }
          SocialLinks {
            id
            SocialLink
            Icon {
              data {
                ...FileFields
              }
            }
          }
        }
      }
    }
  }
`;
