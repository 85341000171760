import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { DynamicsForm } from '../../api';

type Props = {
  form: DynamicsForm;
};

declare const d365mktforms: any;

export const DynamicsFormComponent: React.FC<Props> = ({ form }) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const formElm = d365mktforms.createForm(
      form.DataFormId,
      form.DataFormApiUrl,
      form.DataCachedFormUrl
    );

    container.current?.replaceWith(formElm);
  }, [form]);

  return (
    <Box mb={4}>
      <div ref={container}></div>
    </Box>
  );
};
