import { gql } from '@apollo/client';

export const PAGINATION_FIELDS_FRAGMENT = gql`
  fragment PaginationFields on ResponseCollectionMeta {
    pagination {
      page
      pageSize
      pageCount
      total
    }
  }
`;
