import React, { useMemo } from 'react';

interface Props {
  value: string;
}

export const EnumDisplay: React.FC<Props> = ({ value }) => {
  const formattedValue = useMemo(() => {
    const parts = value.match(/([A-Z][a-z]*)/g);

    if (!parts?.length) return value;

    return parts.join(' ');
  }, [value]);

  return <>{formattedValue}</>;
};
