import { common, grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import buildingImg from './assets/images/teaminsight.jpg';
import { icons } from './icons';

const palette = {
  primary: {
    light: '#b4d337',
    main: '#6CBE45',
    dark: '#3a7c19',
    contrastText: '#ffffff',
  },
  secondary: {
    light: '#069fc8',
    main: '#0D7EC3',
    dark: '#074a74',
    contrastText: '#ffffff',
  },
  transparent: {
    white: 'rgb(255 255 255 / 50%)',
    black: 'rgb(0 0 0 / 80%)',
    total: 'rgb(0, 0, 0, 0)',
    contrastText: '#fff',
  },
  grey: {
    400: '##f1f1f1',
  },
};

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    gradient1: true;
    gradient2: true;
    gradient3: true;
    gradient4: true;
    roundedGrey: true;
    gradientGrey: true;
    gradient1bg: true;
    gradient2bg: true;
    gradient3bg: true;
    gradient4bg: true;
    homeHeader: true;
    mainHeader: true;
    buildingBG: true;
    pageHeader: true;
    pageFooter: true;
    menuContainer: true;
    authorCard: true;
    teamMember: true;
    robotCard: true;
    youTubeContainer: true;
    robotFormPaper: true;
    loadingPaper: true;
    cardBG1: true;
    cardBG2: true;
    cardBG3: true;
    cardBG4: true;
    statBlock: true;
    prototypeCallout: true;
    VideoBanner: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    pagButton: true;
    cardButton: true;
    processButton: true;
    closeButton: true;
  }
}

const defaults = createTheme({ palette });
export const theme = createTheme({
  palette,
  typography: {
    fontFamily: 'Helvetica Neue LT Std !important',
    h1: {
      fontFamily: 'Kanit',
      fontSize: '4.5rem',
      fontWeight: '900',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      textShadow: '0px 8px 16px rgb(0 0 0 / 50%)',
      marginBottom: '1rem',
      [defaults.breakpoints.up('xs')]: {
        fontSize: '2rem',
      },
      [defaults.breakpoints.up('md')]: {
        fontSize: '4.5rem',
      },
    },
    h2: {
      fontFamily: 'Kanit',
      fontSize: '3.5rem',
      fontWeight: '900',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      marginBottom: '1rem',
      textAlign: 'left',
      [defaults.breakpoints.down('md')]: {
        fontSize: '1.75rem',
      },
    },
    h3: {
      fontFamily: 'Kanit',
      fontSize: '1.75rem',
      textTransform: 'uppercase',
      fontWeight: '700',
      letterSpacing: '1.17px',
      marginBottom: '1rem',
      [defaults.breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
    },
    h4: {
      fontFamily: 'Kanit',
      fontSize: '1.5rem',
      fontWeight: '700',
      textTransform: 'uppercase',
      letterSpacing: 'normal',
      marginBottom: '1rem',
      [defaults.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
    h5: {
      fontFamily: 'Kanit',
      fontSize: '1rem',
      fontWeight: '700',
      textTransform: 'uppercase',
      letterSpacing: '0.28px',
      marginBottom: '1rem',
    },
    subtitle1: {
      fontSize: '.75rem',
      textTransform: 'uppercase',
      letterSpacing: '0.28px',
    },
    subtitle2: {
      fontSize: '.75rem',
      fontWeight: '700',
      letterSpacing: '0.28px',
    },
    body1: {
      letterSpacing: '0.28px',
      color: common.black,

      fontFamily: 'azo-sans-web',
      marginBottom: '1rem',
      textAlign: 'left',
      [defaults.breakpoints.up('xs')]: {
        fontSize: '1rem',
      },
      [defaults.breakpoints.up('sm')]: {
        fontSize: '1.25rem',
      },
      '& img': {
        width: '100%',
        maxWidth: '100%',
      },
    },
    body2: {
      fontSize: '.75rem',
      letterSpacing: '0.28px',
    },
  },

  components: {
    /*Paper*/

    MuiPaper: {
      variants: [
        {
          props: { variant: 'VideoBanner' },
          style: {
            clipPath: 'polygon(0 15%, 100% 0, 100% 85%, 0 100%)',
            height: '85vh',
            backgroundColor: grey[200],
            marginTop: '-200px',
            position: 'relative',
            zIndex: '1',
          },
        },

        {
          props: { variant: 'prototypeCallout' },
          style: {
            borderRadius: '0 0 0.5rem 0.5rem',
            backgroundColor: palette.secondary.main,
            marginTop: '4rem',
          },
        },
        {
          props: { variant: 'statBlock' },
          style: {
            position: 'sticky',
            top: '150px',
            border: 'solid thin',
            borderColor: grey[300],
            borderRadius: '1rem',
            padding: '1rem',
            backgroundColor: grey[100],
            width: '100%',
            height: 'fit-content',
          },
        },
        {
          props: { variant: 'robotFormPaper' },
          style: {
            borderRadius: '2rem',
            backgroundColor: grey[200],
            position: 'relative',
            top: '200px',
            marginBottom: '200px',
            width: '100%',
            overflow: 'visible',
            padding: '2rem',
            height: 'fit-content',
            '& .RobotImage': {
              zIndex: '-1',
              position: 'absolute',
              top: '-175px',
              width: '100%',
              transition: 'all .25s ease !important',
              margin: 'auto',
              textAlign: 'center',
            },
            '&:hover': {
              '& .RobotImage': {
                top: '-200px',
              },
            },
          },
        },
        {
          props: { variant: 'loadingPaper' },
          style: {
            display: 'flex',
            width: '100%',
            minHeight: '75vh',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          },
        },
        {
          props: { variant: 'youTubeContainer' },
          style: {
            backgroundColor: common.black,
            position: 'relative',
            paddingTop: '56.25%',
            overflow: 'hidden',
            cursor: 'pointer',
            width: '100%',
            '& iframe': {
              height: '100%',
              width: '100%',
              top: '0',
              left: '0',
              position: 'absolute',
            },
          },
        },

        {
          props: { variant: 'robotCard' },
          style: {
            display: 'block',
            width: '100%',
            padding: '11rem 1rem 1rem 1rem',
            marginBottom: '4rem',
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
            transition: 'all .5s ease',
            color: '#000 !important',
            textDecoration: 'none',
            backgroundColor: palette.transparent.total,
            '&:hover': { padding: '15rem 1rem 1rem 1rem', marginBottom: '0rem' },
          },
        },
        {
          props: { variant: 'teamMember' },
          style: {
            position: 'relative',
            zIndex: '2',
            flex: '1',
            width: '90%',
            margin: '5%',
            marginTop: '-2rem',
            background: common.white,
            boxShadow: '0px 8px 16px rgb(0 0 0 / 50%)',
            borderRadius: '1rem',
            display: 'flex',
            flexDirection: 'column',
            '& a': {
              margin: '0',
              padding: '.5rem',
              minHeight: '3rem',
              borderRadius: '0 0 1rem 1rem',
            },
            '& a:hover': {
              marginBottom: 0,
              marginTop: 0,
            },
          },
        },

        {
          props: { variant: 'authorCard' },
          style: {
            top: '9rem',
            zIndex: '0',
            backgroundColor: grey[200],
            padding: '1.5rem',
            borderRadius: '0.5rem',
            position: 'sticky',
            height: 'fit-content',
          },
        },

        {
          props: { variant: 'menuContainer' },
          style: {
            width: '100%',
            height: '100vh',
            backdropFilter: 'blur(8px)',
            color: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgb(255 255 255 / 90%)',
            boxShadow: '4',
            transition: 'all 0.5s ease-in-out',
            borderRadius: '0',
          },
        },

        {
          props: { variant: 'homeHeader' },
          style: {
            background: 'linear-gradient(180deg,  #fee000, #6cbe45)',
            backgroundSize: '200% 200%',
            animation: 'gradient 8s ease infinite',
            minHeight: '24rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 'calc(10px + 2vmin)',
            color: 'white',
            position: 'relative',
            '&::after': {
              content: '""',
              backgroundImage:
                'linear-gradient(to left top, white 0%, white 50%, transparent 50.5%)',
              position: 'absolute',
              width: '100%',
              height: '12rem',
              bottom: '0',
              left: '0',
              zIndex: '1',
            },
          },
        },

        {
          props: { variant: 'mainHeader' },
          style: {
            background: 'linear-gradient(180deg, #6cbe45, #02a0c6)',
            backgroundSize: '200% 200%',
            animation: 'gradient 8s ease infinite',
            minHeight: '24rem',
            display: 'block',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 'calc(10px + 2vmin)',
            color: 'white',
            position: 'relative',
            '&::after': {
              content: '""',
              backgroundImage:
                'linear-gradient(to left top, white 0%, white 50%, transparent 50.5%)',
              position: 'absolute',
              width: '100%',
              height: '12rem',
              bottom: '0',
              left: '0',
              zIndex: '1',
            },
          },
        },
        {
          props: { variant: 'pageFooter' },
          style: {
            background: 'linear-gradient(180deg, #ee2a25, #fee000)',
            backgroundSize: '200% 200%',
            animation: 'gradient 8s ease infinite',

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            justifyContent: 'center',
            fontSize: 'calc(10px + 2vmin)',
            color: 'white',
            position: 'relative',

            [defaults.breakpoints.up('sm')]: {
              padding: '15rem 5rem 0 5rem',
            },
            [defaults.breakpoints.up('xs')]: {
              padding: '15rem 0 0 0',
            },
            '&::before': {
              content: '""',
              backgroundImage:
                'linear-gradient(to right bottom, white 0%, white 50%, transparent 50.5%)',
              position: 'absolute',
              width: '100%',
              height: '12rem',
              top: '0',
              left: '0',
              zIndex: '1',
            },
          },
        },
        {
          props: { variant: 'buildingBG' },
          style: {
            content: '""',
            position: 'absolute',
            top: '0',
            left: '0',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgb(0, 0, 0, 0)',
            background: buildingImg,
            filter: 'grayscale(1)',
            backgroundPosition: 'left center',
            backgroundSize: 'cover',
            animation: 'gradient 64s infinite',
          },
        },
        {
          props: { variant: 'gradient1' },
          style: {
            background: 'linear-gradient(180deg, #fee000, #6cbe45)',
            backgroundSize: '200% 200%',
            animation: 'gradient 8s ease infinite',
            padding: '16rem 1rem',
            marginTop: '0',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&::after': {
              content: '""',
              backgroundImage:
                'linear-gradient(to left top, white 0%, white 50%, transparent 50.5%)',
              position: 'absolute',
              width: '100%',
              height: '12rem',
              bottom: '0',
              left: '0',
              zIndex: '1',
            },
            '&::before': {
              content: '""',
              backgroundImage:
                'linear-gradient(to right bottom, white 0%, white 50%, transparent 50.5%)',
              position: 'absolute',
              width: '100%',
              height: '12rem',
              top: '0',
              left: '0',
              zIndex: '1',
            },
          },
        },
        {
          props: { variant: 'gradient2' },
          style: {
            background: 'linear-gradient(180deg, #6cbe45, #02a0c6)',
            backgroundSize: '200% 200%',
            animation: 'gradient 8s ease infinite',
            padding: '16rem 1rem',
            marginTop: '0',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&::after': {
              content: '""',
              backgroundImage:
                'linear-gradient(to left top, white 0%, white 50%, transparent 50.5%)',
              position: 'absolute',
              width: '100%',
              height: '12rem',
              bottom: '0',
              left: '0',
              zIndex: '1',
            },
            '&::before': {
              content: '""',
              backgroundImage:
                'linear-gradient(to right bottom, white 0%, white 50%, transparent 50.5%)',
              position: 'absolute',
              width: '100%',
              height: '12rem',
              top: '0',
              left: '0',
              zIndex: '1',
            },
          },
        },
        {
          props: { variant: 'gradient3' },
          style: {
            background: 'linear-gradient(180deg, #02a0c6, #ee2a25)',
            backgroundSize: '200% 200%',
            animation: 'gradient 8s ease infinite',
            padding: '16rem 1rem',
            marginTop: '0',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&::after': {
              content: '""',
              backgroundImage:
                'linear-gradient(to left top, white 0%, white 50%, transparent 50.5%)',
              position: 'absolute',
              width: '100%',
              height: '12rem',
              bottom: '0',
              left: '0',
              zIndex: '1',
            },
            '&::before': {
              content: '""',
              backgroundImage:
                'linear-gradient(to right bottom, white 0%, white 50%, transparent 50.5%)',
              position: 'absolute',
              width: '100%',
              height: '12rem',
              top: '0',
              left: '0',
              zIndex: '1',
            },
          },
        },
        {
          props: { variant: 'gradient4' },
          style: {
            background: 'linear-gradient(180deg, #ee2a25, #fee000)',
            backgroundSize: '200% 200%',
            animation: 'gradient 8s ease infinite',
            padding: '16rem 1rem',
            marginTop: '0',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&::after': {
              content: '""',
              backgroundImage:
                'linear-gradient(to left top, white 0%, white 50%, transparent 50.5%)',
              position: 'absolute',
              width: '100%',
              height: '12rem',
              bottom: '0',
              left: '0',
              zIndex: '1',
            },
            '&::before': {
              content: '""',
              backgroundImage:
                'linear-gradient(to right bottom, white 0%, white 50%, transparent 50.5%)',
              position: 'absolute',
              width: '100%',
              height: '12rem',
              top: '0',
              left: '0',
              zIndex: '1',
            },
          },
        },
        {
          props: { variant: 'gradientGrey' },
          style: {
            background: grey[200],
            padding: '16rem 1rem',
            marginTop: '0',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&::after': {
              content: '""',
              backgroundImage:
                'linear-gradient(to left top, white 0%, white 50%, transparent 50.5%)',
              position: 'absolute',
              width: '100%',
              height: '12rem',
              bottom: '0',
              left: '0',
              zIndex: '1',
            },
            '&::before': {
              content: '""',
              backgroundImage:
                'linear-gradient(to right bottom, white 0%, white 50%, transparent 50.5%)',
              position: 'absolute',
              width: '100%',
              height: '12rem',
              top: '0',
              left: '0',
              zIndex: '1',
            },
          },
        },

        {
          props: { variant: 'roundedGrey' },
          style: {
            background: grey[200],
            padding: '2rem',
            marginTop: '0',
            borderRadius: '1rem',
            marginBottom: '1rem',
            display: 'flex',
            flexDirection: 'column',
          },
        },

        {
          props: { variant: 'gradient1bg' },
          style: {
            background: 'linear-gradient(180deg, #fee000, #6cbe45)',
          },
        },
        {
          props: { variant: 'gradient2bg' },
          style: {
            background: 'linear-gradient(180deg, #6cbe45, #02a0c6)',
          },
        },
        {
          props: { variant: 'gradient3bg' },
          style: {
            background: 'linear-gradient(180deg, #02a0c6, #ee2a25)',
          },
        },
        {
          props: { variant: 'gradient4bg' },
          style: {
            background: 'linear-gradient(180deg, #ee2a25, #fee000)',
          },
        },
        {
          props: { variant: 'cardBG1' },
          style: {
            background: 'linear-gradient(180deg, #fee000, #6cbe45)',
            display: 'flex',
            justifyContent: 'center',
          },
        },
        {
          props: { variant: 'cardBG2' },
          style: {
            background: 'linear-gradient(180deg, #6cbe45, #02a0c6)',
            display: 'flex',
            justifyContent: 'center',
          },
        },
        {
          props: { variant: 'cardBG3' },
          style: {
            background: 'linear-gradient(180deg, #02a0c6, #ee2a25)',
            display: 'flex',
            justifyContent: 'center',
          },
        },
        {
          props: { variant: 'cardBG4' },
          style: {
            background: 'linear-gradient(180deg, #ee2a25, #fee000)',
            display: 'flex',
            justifyContent: 'center',
          },
        },
      ],
    },
    /*Chip*/
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: 'azo-sans-web!important',
        },
      },
    },

    /*Drawer*/
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: '1000',
          '& .MuiPaper-root': { backgroundColor: palette.transparent.total },
          '& .MuiBox-root': {
            height: '100vh',
            backgroundColor: palette.transparent.black,
            color: common.white,
            backdropFilter: 'blur( 8px )',
          },
        },
      },
    },

    /*Link*/
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.secondary.main,
        },
        underlineAlways: {
          color: palette.secondary.main,
          textDecorationColor: palette.secondary.main,
        },
      },
    },

    /*List*/
    MuiList: {
      styleOverrides: {
        root: {
          listStyleType: 'disc',
          paddingLeft: '1.5rem',
        },
      },
    },

    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: common.black,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
          flexDirection: 'column',
          alignItems: 'start',
          width: 'fit-content',
          [defaults.breakpoints.up('xs')]: {
            padding: '.125rem',
          },
          [defaults.breakpoints.up('sm')]: {
            padding: '.5rem 1rem',
          },
        },
        dense: {
          display: 'list-item',
          '&::marker': {
            fontSize: '1em',
            display: 'list-item',
            content: icons.check,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: { '& .MuiTypography-root': { color: common.white } },
        primary: {
          fontFamily: 'azo-sans-web',
          fontWeight: '800',
          textTransform: 'uppercase',
          marginBottom: '0',
          [defaults.breakpoints.up('xs')]: {
            fontSize: '1.25rem',
          },
          [defaults.breakpoints.up('md')]: {
            fontSize: '2rem',
          },
        },
        secondary: {
          fontFamily: 'azo-sans-web!important',
          textTransform: 'uppercase',
          fontWeight: '700',
          [defaults.breakpoints.up('xs')]: {
            fontSize: '1rem',
          },
          [defaults.breakpoints.up('md')]: {
            fontSize: '1.25rem',
          },
        },
      },
    },
    /*! buttons */

    MuiButton: {
      variants: [
        {
          props: { variant: 'closeButton' },
          style: {
            position: 'absolute',
            top: '.5rem',
            right: '.5rem',
            minWidth: '2.5rem',
            minHeight: '2.5rem',
            padding: '.5rem',
            backgroundColor: common.black,
            color: common.white,
            transition: 'all .25s ease !important',
            '& p': {
              color: common.black,
              margin: '0',
            },
            '&:hover': {
              backgroundColor: common.black,
              top: '.25rem',
              boxShadow: '0px 16px 16px rgb(0 0 0 / 50%)',
            },
          },
        },
        {
          props: { variant: 'pagButton' },
          style: {
            borderColor: common.black,
            color: common.white,
            backgroundColor: common.black,
            fontFamily: 'azo-sans-web!important',
            fontSize: '1.25rem',
            margin: '1rem 2rem',
            transition: 'all .25s ease !important',
            '&:disabled': {
              backgroundColor: grey[500],
              color: common.white,
              fontFamily: 'azo-sans-web!important',
            },
            '&:hover': {
              backgroundColor: common.black,
              margin: '.5rem 2rem 1.5rem 2rem',
              fontFamily: 'azo-sans-web!important',
              boxShadow: '0px 16px 16px rgb(0 0 0 / 50%)',
            },
          },
        },
        {
          props: { variant: 'cardButton' },
          style: {
            borderColor: common.black,
            color: common.white,
            backgroundColor: common.black,
            fontFamily: 'azo-sans-web',
            fontSize: '1.25rem',

            '&:hover': {
              backgroundColor: common.black,
              marginTop: '0rem',

              boxShadow: '0px 16px 16px rgb(0 0 0 / 50%)',
            },
          },
        },
        {
          props: { variant: 'processButton' },
          style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '.25rem',
            minHeight: 'auto',
            width: '12rem',
            padding: '.5rem',
            marginTop: '.25rem',
            marginBottom: '-.25rem',
            cursor: 'pointer',
            transition: 'all .25s ease !important',
            backgroundColor: palette.transparent.white,
            borderRadius: '.5rem',
            boxShadow: '0px 8px 16px rgb(0 0 0 / 20%)',
            '&:hover': {
              marginTop: '-.25rem',
              marginBottom: '.25rem',
              boxShadow: '0px 16px 16px rgb(0 0 0 / 50%)',
              backgroundColor: palette.primary.light,
            },
            '& svg': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '2rem',
              height: '2rem',
              marginBottom: '.5rem',
              borderRadius: '50%',
              background: 'rgba( 255, 255, 255, 0.75 )',
              backdropFilter: 'blur( 8px )',
              boxShadow: '0px 4px 8px rgb(0 0 0 / 20%)',
              padding: '1rem',
            },
          },
        },
      ],
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        contained: {
          backgroundColor: common.black,
          fontFamily: 'azo-sans-web',

          borderRadius: '.5rem',
          boxShadow: '0px 8px 16px rgb(0 0 0 / 50%);',
          lineHeight: 'normal',

          transition: 'all .25s ease !important',

          '& p': { margin: '0 .5rem', color: common.white },
          '& hover': {
            marginTop: '-0.25rem',
            marginBottom: '0.25rem',
            boxShadow: '0px 16px 16px rgb(0 0 0 / 50%)',
          },
          [defaults.breakpoints.up('xs')]: {
            padding: '.5rem 1rem',
          },
          [defaults.breakpoints.up('md')]: {
            padding: '1rem 1.5rem',
          },
        },
        containedPrimary: {
          borderColor: common.black,
          color: common.white,
          backgroundColor: common.black,
          fontFamily: 'azo-sans-web!important',
          fontSize: '1.25rem',
          '&:hover': {
            backgroundColor: common.black,
            marginTop: '-0.25rem',

            boxShadow: '0px 16px 16px rgb(0 0 0 / 50%)',
          },
          [defaults.breakpoints.up('xs')]: {
            padding: '.5rem 1rem',
            minHeight: '3rem',
            minWidth: '2rem',
            margin: '0 .25rem .5rem 0',
            '&:hover': {
              marginBottom: '0.75rem',
            },
          },
          [defaults.breakpoints.up('md')]: {
            padding: '1rem 1.5rem',
            minHeight: '4rem',
            minWidth: '4.5rem',
            margin: '0 .5rem 1rem 0',
            '&:hover': {
              marginBottom: '1.25rem',
            },
          },
        },
        outlinedPrimary: {
          borderColor: palette.primary.main,
          color: palette.primary.main,
          backgroundColor: palette.transparent.total,
          borderWidth: '2px',
          fontSize: '.75rem',
          padding: '.5rem',
          borderRadius: '0',
          border: 'solid 2px',
          width: 'fit-content',
          marginLeft: 'auto',
          marginRight: 'auto',
          fontFamily: 'azo-sans-web!important',
          '&:hover': {
            backgroundColor: palette.primary.main,
            borderColor: palette.primary.main,
            borderWidth: '2px',
          },
          '&:active': {
            borderColor: palette.secondary.main,
            color: palette.primary.main,
            backgroundColor: palette.secondary.main,
            boxShadow: defaults.shadows[0],
          },
        },
        containedSecondary: {
          borderColor: palette.primary.main,
          color: '#ffffff',
          backgroundColor: palette.primary.main,
          fontFamily: 'azo-sans-web!important',
          '&:hover': {
            borderColor: palette.secondary.main,
            color: palette.primary.main,
            backgroundColor: palette.secondary.main,
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: { padding: '2rem 0', maxWidth: '1500px' },
        maxWidthXl: {
          [defaults.breakpoints.up('xs')]: {
            maxWidth: '1900px',
            padding: '0 1.25rem',
          },
          [defaults.breakpoints.up('sm')]: {
            padding: '0 1.5rem',
          },
        },
      },
    },

    /*Grid*/
    MuiGrid: {
      styleOverrides: {
        item: { display: 'flex' },
      },
    },

    /*Drawer*/
    MuiBackdrop: {
      styleOverrides: {
        root: { backgroundColor: 'rgba(0, 0, 0, 0.75)' },
      },
    },

    /*Cards*/
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 8px 16px rgb(0 0 0 / 50%)',
          borderRadius: '.5rem',
          backgroundColor: common.white,
          textAlign: 'left',
          display: 'flex!important',
          flexDirection: 'column',
          flex: '1 1 auto',

          '& h3': { width: '100%' },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: { display: 'flex!important', flexDirection: 'column', flex: '1 1 auto' },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: palette.secondary.main,
          color: common.white,
          textAlign: 'center',
          '& span': {
            margin: '0',
            fontSize: '1.25rem',
            fontFamily: 'Kanit',
            textTransform: 'uppercase',
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          backgroundColor: common.black,
          color: common.white,
          display: 'flex',
          justifyContent: 'center',
          padding: '1rem',
          '& p': {
            fontSize: '1.25rem',
            color: common.white,
            margin: '0',

            textTransform: 'uppercase',
          },
        },
      },
    },
  },
});
