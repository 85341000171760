import { gql } from '@apollo/client';

export const DYNAMICS_FORM_FRAGMENT = gql`
  fragment DynamicsFormField on DynamicsFormEntity {
    id
    attributes {
      FormName
      DataFormId
      DataFormApiUrl
      DataCachedFormUrl
    }
  }
`;
