import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarAlt,
  faPhone,
  faBars,
  faBrain,
  faPalette,
  faLightbulb,
  faPeopleGroup,
  faCode,
  faPaperPlane,
  faSquareCheck,
  faForward,
  faHandshake,
  faColumns,
  faList,
  faTasks,
  faSearch,
  faRocket,
  faShieldAlt,
  faLeftLong,
  faRightLong,
  faUpLong,
  faDownLong,
  faXmark,
  faTriangleExclamation,
  faFilter,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';

export const icons = {
  calendar: faCalendarAlt as IconProp,
  phone: faPhone as IconProp,
  menu: faBars as IconProp,
  brain: faBrain as IconProp,
  portfolio: faPalette as IconProp,
  insights: faLightbulb as IconProp,
  team: faPeopleGroup as IconProp,
  code: faCode as IconProp,
  plane: faPaperPlane as IconProp,
  check: faSquareCheck as IconProp,
  up: faUpLong as IconProp,
  down: faDownLong as IconProp,
  left: faLeftLong as IconProp,
  right: faRightLong as IconProp,
  forward: faForward as IconProp,
  handshake: faHandshake as IconProp,
  columns: faColumns as IconProp,
  list: faList as IconProp,
  tasks: faTasks as IconProp,
  search: faSearch as IconProp,
  rocket: faRocket as IconProp,
  shield: faShieldAlt as IconProp,
  close: faXmark as IconProp,
  caution: faTriangleExclamation as IconProp,
  filter: faFilter as IconProp,
  expand: faChevronDown as IconProp,
  collapse: faChevronUp as IconProp,
};
