import { EntityID, EntityItem, File } from '../types';

export enum SocialNetwork {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
}

export interface SEOSocialNetwork {
  id: EntityID;
  socialNetwork: SocialNetwork;
  title: string;
  description: string;
  image: EntityItem<File>;
}

export interface SEO {
  id: EntityID;
  metaTitle: string;
  metaDescription: string;
  metaImage: EntityItem<File>;
  keywords: string;
  metaRobots: string;
  structuredData: string;
  metaViewport: string;
  canonicalURL: string;
  metaSocial: Array<SEOSocialNetwork>;
}
