import React from 'react';
import { CTASection, PageBanner, SwiperShell, YouTubeEmbed } from '../shared/layout/components';
import { Container, Box, Typography, Card, CardHeader, CardContent, Grid } from '@mui/material';
import { SwiperSlide } from 'swiper/react';
import { ClientCard } from './components';
import { icons } from '../icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useClientCards } from '../api/about/clientCard.query';
import { ButtonLink, LoadingIndicator, Meta } from '../shared/components';
import { useTestimonial } from '../api';
import { useTestimonialPage } from '../api/testimonials/testimonialPage.query';

export const TestimonialsPage: React.FC = () => {
  const testimonialPageQuery = useTestimonialPage();
  const testimonialQuery = useTestimonial();
  const clientCardQuery = useClientCards();
  if (!clientCardQuery.data || !testimonialQuery.data || !testimonialPageQuery.data)
    return <LoadingIndicator />;
  return (
    <Box>
      <Meta seo={testimonialPageQuery.data?.testimonialsPage.data.attributes.SEO} />
      <PageBanner
        headline={testimonialPageQuery.data?.testimonialsPage.data.attributes.Banner.Headline}
        subCopy={testimonialPageQuery.data?.testimonialsPage.data.attributes.Banner.Subhead}
      />
      <Box py={3} textAlign="center">
        <Typography variant="h2" color="primary.main" textAlign="center">
          What our clients say
        </Typography>
        <SwiperShell>
          {testimonialQuery.data?.testimonials.data.map(x => (
            <SwiperSlide key={x.id}>
              <Card>
                <CardHeader title={x.attributes.ClientName} />

                <CardContent>
                  <Typography>{x.attributes.TestimonialCopy}</Typography>
                </CardContent>
              </Card>
            </SwiperSlide>
          ))}
        </SwiperShell>
        <ButtonLink to="/contact-us">How can we help you</ButtonLink>
      </Box>
      <Container maxWidth="xl">
        <Typography variant="h2" color="primary.main" textAlign="center">
          Check out who we've helped
        </Typography>
        <Grid container py={4} spacing={4}>
          {clientCardQuery.data?.clientCards.data.map(x => (
            <Grid item xs={12} lg={3} key={x.id}>
              <ClientCard clientCard={x.attributes} />
            </Grid>
          ))}
        </Grid>
        <Grid container my={4} spacing={4}>
          {testimonialPageQuery.data?.testimonialsPage.data.attributes.VideoTestimonials.map(x => (
            <Grid item xs={12} lg={4} key={x.id}>
              <YouTubeEmbed url={x.YoutubeEmbedUrl} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <CTASection
        title="We are ready to help"
        linkCopy="Contact"
        img={icons.plane as IconProp}
        link="/contact-us"
      />
    </Box>
  );
};
