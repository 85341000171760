import React from 'react';
import { Paper } from '@mui/material';

type Props = {
  url: string;
};

export const YouTubeEmbed: React.FC<Props> = ({ url }) => (
  <Paper variant="youTubeContainer">
    <iframe
      src={url}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    ></iframe>
  </Paper>
);
