import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CTASection, Markdown } from '../shared/layout/components';
import { Container, Box, Grid, Typography } from '@mui/material';
import { useArticle } from '../api/insights';
import { AuthorCard, InsightPageBanner } from './components';
import { Meta, useMediaUrl, LoadingIndicator } from '../shared';
import { icons } from '../icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const InsightArticle: React.FC = () => {
  const { slug } = useParams();
  const insightArticle = useArticle(slug!);

  const article = useMemo(() => {
    if (!insightArticle.data?.insightArticles.data.length) return null;

    return insightArticle.data.insightArticles.data[0];
  }, [insightArticle]);

  const photoUrl = useMediaUrl(article?.attributes.employee.data.attributes.photo.data.attributes);

  if (!article) return <LoadingIndicator />;

  return (
    <Box>
      <Meta seo={article.attributes.SEO} />
      <InsightPageBanner
        headline={article.attributes.Title}
        author={article.attributes.employee.data.attributes.name}
        date={article.attributes.PublishDate}
      />
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} md={8} flexDirection="column">
            <Markdown children={article.attributes.ArticleCopy} />
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h5" component="p" m="0">
                Tagged as:
              </Typography>
              {article.attributes.insight_tags?.data.map(x => (
                <Typography
                  key={x.id}
                  sx={{
                    backgroundColor: 'grey.200',
                    marginX: '.5rem',
                    marginY: '0',
                    padding: '.25rem .5rem',
                    fontSize: '.75rem!important',
                    borderRadius: '.25rem',
                  }}
                >
                  {x.attributes.tag}
                </Typography>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <AuthorCard
              image={photoUrl}
              author={article.attributes.employee.data.attributes.name}
              bio={article.attributes.employee.data.attributes.bio}
              contactText={
                article.attributes.employee.data.attributes.AuthorContactInfo?.ContactText
              }
              contactLink={
                article.attributes.employee.data.attributes.AuthorContactInfo?.ContactLink!
              }
            />
          </Grid>
        </Grid>
      </Container>
      <CTASection
        title="We are ready to help you"
        img={icons.plane as IconProp}
        link="/contact-us"
        linkCopy="Contact Us"
      />
    </Box>
  );
};
