import React from 'react';
import { Typography, Box } from '@mui/material';

type Props = {
  date: number;
  children?: React.ReactNode;
};

export const AwardDate: React.FC<Props> = ({ date, children }) => {
  return (
    <Box sx={{ marginBottom: '1rem' }}>
      <Typography variant="h4" component="p" m={0}>
        {date}
      </Typography>
      {children}
    </Box>
  );
};
