import { gql, useQuery } from '@apollo/client';

import { FILE_FIELDS_FRAGMENT } from '../fragments';
import { CollectionQueryOptions, CollectionResponse } from '../types';
import { formatSort } from '../utils';
import { Employee } from './types';

interface QueryResult {
  employees: CollectionResponse<Employee>;
}

export function useEmployees(options: CollectionQueryOptions<Employee>) {
  return useQuery<QueryResult>(query, {
    variables: {
      pagination: options.pagination,
      sort: formatSort(options.sortBy),
      publicationState: options.publicationState,
    },
  });
}

const query = gql`
  ${FILE_FIELDS_FRAGMENT}
  query GetAllEmployees(
    $pagination: PaginationArg
    $sort: [String]
    $publicationState: PublicationState
  ) {
    employees(pagination: $pagination, sort: $sort, publicationState: $publicationState) {
      data {
        id
        attributes {
          name
          title
          bio
          photo {
            data {
              ...FileFields
            }
          }
          reactionPhoto {
            data {
              ...FileFields
            }
          }
          EmployeeContactInfo {
            ContactLink
            ContactText
          }
        }
      }
    }
  }
`;
