import { gql, useQuery } from '@apollo/client';

import { FILE_FIELDS_FRAGMENT } from '../fragments';
import { CollectionResponse } from '../types';
import { Achievement } from './types';

interface QueryResult {
  achievements: CollectionResponse<Achievement>;
}

export function useFeaturedAchievements() {
  return useQuery<QueryResult>(query);
}

const query = gql`
  ${FILE_FIELDS_FRAGMENT}
  query GetAchievements {
    achievements {
      data {
        id
        attributes {
          name
          link
          featured
          image {
            data {
              ...FileFields
            }
          }
        }
      }
    }
  }
`;
