import React from 'react';
import { Box, Button, Paper, Typography, styled } from '@mui/material';
import { Employee } from '../../api';
import { useMediaUrl } from '../../shared';

type Props = {
  employee: Employee;
};

const Image = styled('img')(() => ({
  position: 'relative',
  borderRadius: '1rem',
  width: '100%',
  maxWidth: '600px',
  maxHeight: '600px',
  height: 'auto',
}));

export const TeamCard: React.FC<Props> = ({ employee }) => {
  const photoUrl = useMediaUrl(employee.photo.data.attributes);

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Image src={photoUrl} alt={employee.name} />

      <Paper variant="teamMember">
        <Box p={2}>
          <Typography variant="h4">{employee.name}</Typography>
          <Typography>{employee.title}</Typography>
        </Box>

        {employee.EmployeeContactInfo && (
          <Button href={employee.EmployeeContactInfo.ContactLink}>
            {employee.EmployeeContactInfo.ContactText}
          </Button>
        )}
      </Paper>
    </Box>
  );
};
