import React from 'react';
import { Typography, Paper, Box } from '@mui/material';

type Props = {
  headline?: string;
  author?: string;
  date?: string;
};

export const InsightPageBanner: React.FC<Props> = ({ headline, author, date }) => (
  <Paper variant="mainHeader">
    <Box sx={{ maxWidth: '1900px', padding: '12rem 0', marginLeft: 'auto', marginRight: 'auto' }}>
      <Box sx={{ padding: '0 1.5rem' }}>
        <Typography variant="h1">{headline}</Typography>
        <Typography variant="h4" component="p" color="common.black" m="0">
          By: {author}
        </Typography>
        <Typography>Published: {date}</Typography>
      </Box>
    </Box>
  </Paper>
);
