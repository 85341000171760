import React from 'react';
import { Stack, Typography, Box, Link } from '@mui/material';
import { Employee } from '../../api';
import { Image } from '../../shared';

type Props = {
  employee: Employee;
};

export const EmployeeCallout: React.FC<Props> = ({ employee }) => {
  return (
    <Box>
      <Image file={employee.photo.data.attributes} />
      <Stack p={2}>
        <Typography variant="h3" mb={0}>
          {employee.name}
        </Typography>
        <Typography>{employee.title}</Typography>
        <Link href={employee.EmployeeContactInfo.ContactLink}>
          {employee.EmployeeContactInfo.ContactText}
        </Link>
      </Stack>
    </Box>
  );
};
