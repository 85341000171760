import React from 'react';
import { CTASection, Markdown, PageBanner } from '../shared/layout/components';
import { Container, Box, Grid } from '@mui/material';
import { LpPrototypeCard } from './components';
import { icons } from '../icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { usePrototypePage } from '../api';
import { LoadingIndicator, Meta } from '../shared';

export const PrototypesLandingPage: React.FC = () => {
  const prototypeQuery = usePrototypePage();
  if (!prototypeQuery.data) return <LoadingIndicator />;
  return (
    <Box>
      <Meta seo={prototypeQuery.data.powerOfPrototypesPage.data.attributes.SEO} />
      <PageBanner
        headline={prototypeQuery.data.powerOfPrototypesPage.data.attributes.Banner.Headline}
        subCopy={prototypeQuery.data.powerOfPrototypesPage.data.attributes.Banner.Subhead}
      />
      <Container maxWidth="lg">
        <Markdown children={prototypeQuery.data.powerOfPrototypesPage.data.attributes.Copy} />
        <Grid container spacing={4}>
          {prototypeQuery.data.powerOfPrototypesPage.data.attributes.Prototypes.map(x => (
            <Grid item xs={12} sm={6} md={4} key={x.id}>
              <LpPrototypeCard prototype={x} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <CTASection
        title="We are ready to help you"
        linkCopy="Contact Us"
        link="/contact-us"
        img={icons.plane as IconProp}
      />
    </Box>
  );
};
