import React from 'react';
import { PageBanner } from '../shared/layout/components';
import { Container, Box, Typography, Link } from '@mui/material';

export const ConsultationThankYouPage: React.FC = () => {
  return (
    <Box>
      <PageBanner headline="Your Consultation is registered" />
      <Container maxWidth="lg">
        <Box p={5}>
          <Typography>
            One of our fearless leaders, will be reaching out to you soon about your consultation.
          </Typography>
          <Typography>The Envative team looks forward to talking to you</Typography>
          <Typography>
            <Link href="/">Take me home</Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
