import { gql, useQuery } from '@apollo/client';
import { SEO_FIELDS_FRAGMENT } from '../fragments';
import { CollectionResponse } from '../types';
import { CareerItem } from './types';

interface QueryResult {
  careers: CollectionResponse<CareerItem>;
}

export function useCareerItem(slug: string) {
  return useQuery<QueryResult>(query, { variables: { slug } });
}

const query = gql`
  ${SEO_FIELDS_FRAGMENT}
  query GetCareerItem($slug: String) {
    careers(filters: { Slug: { eq: $slug } }) {
      data {
        id
        attributes {
          Banner {
            Headline
            Subhead
          }
          SEO {
            ...SEOFields
          }
          Copy
          Slug
          CareerName
          Description
        }
      }
    }
  }
`;
