import React from 'react';
import { List } from '@mui/material';
import { icons } from '../../../icons';
import { ListItemObj, SubListContainer, SubListItem } from './compontents';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const Menu: React.FC = () => {
  return (
    <List component="div">
      <ListItemObj
        primary="Services and Expertise"
        img={icons.brain as IconProp}
        url="/services-and-expertise"
      >
        <SubListContainer>
          <SubListItem secondary="Code Reviews" url="/services-and-expertise/code-reviews" />
          <SubListItem
            secondary="Custom Web"
            url="/services-and-expertise/custom-web-development"
          />
          <SubListItem
            secondary="Mobile Apps"
            url="/services-and-expertise/mobile-app-development"
          />
          <SubListItem secondary="IoT Solutions" url="/services-and-expertise/iot-solutions" />
          <SubListItem
            secondary="Software Prototyping"
            url="/services-and-expertise/software-prototyping"
          />
          <SubListItem secondary="Websites" url="/services-and-expertise/websites" />
        </SubListContainer>
      </ListItemObj>
      <ListItemObj primary="Portfolio" img={icons.portfolio as IconProp} url="/portfolio" />
      <ListItemObj primary="Insights" img={icons.insights as IconProp} url="/insights" />
      <ListItemObj primary="About Us" img={icons.team as IconProp} url="/about-us" />
      <ListItemObj primary="Careers" img={icons.code as IconProp} url="/careers" />
    </List>
  );
};
