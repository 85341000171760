import React, { useCallback, useMemo } from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Entity, InsightArticleFilters, InsightTag, InsightType, useInsightTags } from '../../api';
import { Collapsable, EnumDisplay } from '../../shared';
import { icons } from '../../icons';

interface Props {
  filters: InsightArticleFilters;
  onChange: (filters: InsightArticleFilters) => void;
}

export const InsightFilters: React.FC<Props> = ({ filters, onChange }) => {
  const tagQuery = useInsightTags();

  const types = useMemo(() => {
    const insightTypes = Object.values(InsightType);

    return {
      selected: insightTypes.filter(x => filters.types.includes(x)),
      unselected: insightTypes.filter(x => !filters.types.includes(x)),
    };
  }, [filters]);

  const tags = useMemo(() => {
    const insightTags = tagQuery.data?.insightTags.data ?? [];

    return {
      selected: insightTags.filter(x => filters.tags.includes(x.attributes.tag)),
      unselected: insightTags.filter(x => !filters.tags.includes(x.attributes.tag)),
    };
  }, [tagQuery, filters]);

  const addTagFilter = useCallback(
    (tag: Entity<InsightTag>) => {
      onChange({
        types: filters.types,
        tags: [...filters.tags, tag.attributes.tag].sort(),
      });
    },
    [filters, onChange]
  );

  const removeTagFilter = useCallback(
    (tag: Entity<InsightTag>) => {
      onChange({
        types: filters.types,
        tags: filters.tags.filter(x => x !== tag.attributes.tag),
      });
    },
    [filters, onChange]
  );

  const addTypeFilter = useCallback(
    (type: InsightType) => {
      onChange({
        types: [...filters.types, type].sort(),
        tags: filters.tags,
      });
    },
    [filters, onChange]
  );

  const removeTypeFilter = useCallback(
    (type: InsightType) => {
      onChange({
        types: filters.types.filter(x => x !== type),
        tags: filters.tags,
      });
    },
    [filters, onChange]
  );

  return (
    <Stack sx={{ position: 'sticky', top: '9rem', px: 2 }}>
      <Typography variant="h3">Filtering</Typography>
      <Collapsable
        title={
          <Typography color="secondary" sx={{ mb: 0 }}>
            <strong>
              Filter by Insight Type <FontAwesomeIcon icon={icons.filter} />
            </strong>
          </Typography>
        }
      >
        {types.selected.map(x => (
          <Chip
            key={x}
            label={<EnumDisplay value={x} />}
            color="secondary"
            clickable
            onClick={() => removeTypeFilter(x)}
            sx={{ mb: 1, mr: 1 }}
          />
        ))}
        {types.unselected.map(x => (
          <Chip
            key={x}
            label={<EnumDisplay value={x} />}
            clickable
            onClick={() => addTypeFilter(x)}
            sx={{ mb: 1, mr: 1 }}
          />
        ))}
      </Collapsable>
      <Collapsable
        title={
          <Typography color="secondary" sx={{ mb: 0 }}>
            <strong>
              Filter by Tag <FontAwesomeIcon icon={icons.filter} />
            </strong>
          </Typography>
        }
      >
        {tags.selected.map(x => (
          <Chip
            key={x.id}
            label={x.attributes.tag}
            color="secondary"
            clickable
            onClick={() => removeTagFilter(x)}
            sx={{ mb: 1, mr: 1 }}
          />
        ))}
        {tags.unselected.map(x => (
          <Chip
            key={x.id}
            label={x.attributes.tag}
            clickable
            onClick={() => addTagFilter(x)}
            sx={{ mb: 1, mr: 1 }}
          />
        ))}
      </Collapsable>
    </Stack>
  );
};
