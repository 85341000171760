import React from 'react';
import { PageBanner } from '../shared/layout/components';
import { Container, Box, Typography, Link } from '@mui/material';
import { MetaStatic } from '../shared';
import metaImage from '../assets/images/envative-building-bg.jpg';

export const ContactThankYouPage: React.FC = () => {
  return (
    <Box>
      <MetaStatic title="Thank you!" description="Thank you for contacting us" image={metaImage} />
      <PageBanner headline="Thank You For Contacting Us" />
      <Container maxWidth="lg">
        <Box p={5}>
          <Typography>
            One of our fearless leaders, will be reaching out to you soon to find out more about
            your exciting ideas.
          </Typography>
          <Typography>The Envative team looks forward to talking to you</Typography>
          <Typography>
            <Link href="/">Take me home</Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
