import React from 'react';
import { Helmet } from 'react-helmet';

import { SEO } from '../../api';
import { useMediaUrl } from '../utils';

interface Props {
  seo?: SEO | null;
}

export const Meta: React.FC<Props> = ({ seo }) => {
  const image = useMediaUrl(seo?.metaImage.data.attributes);
  if (!seo) return null;

  return (
    <Helmet defaultTitle="Envative" titleTemplate="%s - Envative">
      {seo.metaTitle && <title>{seo.metaTitle}</title>}
      {seo.metaDescription && <meta name="description" content={seo.metaDescription} />}
      {seo.metaTitle && <meta property="og:title" content={seo.metaTitle} />}
      {image && <meta property="og:image:type" content="image/png" />}
      {image && <meta name="image" property="og:image" content={image}></meta>}
      {seo.metaTitle && <meta name="twitter:card" content="summary_large_image" />}
      {seo.metaTitle && <meta name="twitter:title" content={seo.metaTitle} />}
      {seo.metaDescription && <meta property="og:description" content={seo.metaDescription} />}
      {seo.metaDescription && <meta name="twitter:description" content={seo.metaDescription} />}
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  );
};
