import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Employee } from '../../api';
import { Image } from '../../shared';

type Props = {
  contactEmployee: Employee;
};

export const ContactEmployeeBlock: React.FC<Props> = ({ contactEmployee }) => {
  return (
    <Grid container spacing={2} sx={{ borderTop: 'solid thin', borderColor: 'grey.300' }}>
      <Grid item xs={4}>
        <Image
          file={contactEmployee.photo.data.attributes}
          sx={{ borderRadius: '.5rem', maxWidth: '100%', height: 'min-content' }}
        />
      </Grid>
      <Grid item xs={8} flexDirection="column" alignItems="center" justifyContent="center">
        <Typography variant="h4" component="p" mb={0}>
          Or meet with {contactEmployee.name}
        </Typography>
        <Typography>{contactEmployee.title}</Typography>

        <Button href={contactEmployee.EmployeeContactInfo.ContactLink}>View my Calendar</Button>
      </Grid>
    </Grid>
  );
};
