import { gql, useQuery } from '@apollo/client';
import { EntityItem } from '../types';
import { PrototypePage } from './types';
import { FILE_FIELDS_FRAGMENT, SEO_FIELDS_FRAGMENT } from '../fragments';

interface QueryResult {
  powerOfPrototypesPage: EntityItem<PrototypePage>;
}

export function usePrototypePage() {
  return useQuery<QueryResult>(query);
}

const query = gql`
  ${FILE_FIELDS_FRAGMENT}
  ${SEO_FIELDS_FRAGMENT}
  query GetPrototypesPage {
    powerOfPrototypesPage {
      data {
        id
        attributes {
          Copy
          Banner {
            Headline
            Subhead
          }
          SEO {
            ...SEOFields
          }
          Prototypes {
            id
            ClientName
            PrototypeLink
            PrototypeImage {
              data {
                ...FileFields
              }
            }
          }
        }
      }
    }
  }
`;
