import { gql, useQuery } from '@apollo/client';
import { FILE_FIELDS_FRAGMENT, SEO_FIELDS_FRAGMENT } from '../fragments';
import { CollectionResponse } from '../types';
import { ThankYouPage } from './types';

interface QueryResult {
  thankYouPages: CollectionResponse<ThankYouPage>;
}

export function useThankYouPage(slug: string) {
  return useQuery<QueryResult>(query, { variables: { slug } });
}

const query = gql`
  ${FILE_FIELDS_FRAGMENT}
  ${SEO_FIELDS_FRAGMENT}
  query GetThankYouPage($slug: String) {
    thankYouPages(filters: { Slug: { eq: $slug } }) {
      data {
        id
        attributes {
          Title
          Slug
          SEO {
            ...SEOFields
          }
          Banner {
            Headline
            Subhead
            BannerButton {
              buttonCopy
              buttonLink
            }
          }
          PageContent
          portfolio_item_pages {
            data {
              id
              attributes {
                ClientName
                Slug
                Banner {
                  Headline
                }

                HomepageSynopsis
                HompageImage {
                  data {
                    ...FileFields
                  }
                }
              }
            }
          }
          insight_articles {
            data {
              id
              attributes {
                Title
                Description
                Slug
                image {
                  data {
                    ...FileFields
                  }
                }
                InsightType
                TimeToReadMin
                LinkCopy
                employee {
                  data {
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          Video {
            id
            YoutubeEmbedUrl
          }
        }
      }
    }
  }
`;
