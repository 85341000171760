import React from 'react';
import { List } from '@mui/material';

type Props = {
  children: React.ReactNode;
};

export const SubListContainer: React.FC<Props> = ({ children }) => {
  return (
    <List
      component="div"
      sx={{
        flexDirection: ['column', 'column', 'row'],
        display: 'flex',
        marginLeft: '2.5rem',
      }}
    >
      {children}
    </List>
  );
};
