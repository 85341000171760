import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button as MaterialButton,
  ButtonProps as MaterialButtonProps,
  Link as MaterialLink,
  LinkProps as MaterialLinkProps,
} from '@mui/material';

export type LinkProps = MaterialLinkProps<typeof RouterLink>;

export const Link: React.FC<LinkProps> = props => (
  <MaterialLink component={RouterLink} {...props} />
);

export type ButtonProps = MaterialButtonProps<typeof RouterLink>;

export const ButtonLink: React.FC<ButtonProps> = props => (
  <MaterialButton component={RouterLink} {...props} />
);
