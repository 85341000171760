import React from 'react';
import { Typography, Box, Button, Modal } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../../icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
type Props = {
  name: string;
  description: string;
  img: IconProp;
};
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '1rem',
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  '& h2': { color: 'primary.main' },
};

const iconStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '2rem',
  height: '2rem',
  marginBottom: '.5rem',
  borderRadius: '50%',
  background: 'rgba( 255, 255, 255, 0.75 )',
  backdropFilter: 'blur( 8px )',
  boxShadow: '0px 4px 8px rgb(0 0 0 / 20%)',
  padding: '1rem',
};

export const ProcessModal: React.FC<Props> = ({ name, description, img }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Button variant="processButton" onClick={handleOpen}>
        <FontAwesomeIcon icon={img} />

        <Typography variant="h4" component="p">
          {name}
        </Typography>
        <Typography>View Details</Typography>
      </Button>

      <Modal open={open} onClose={handleClose} aria-labelledby={name}>
        <Box sx={style}>
          <Box sx={iconStyle}>
            <FontAwesomeIcon icon={img} size="2xl" />
          </Box>
          <Typography id="modal-modal-title" variant="h3" component="h2" textAlign="center">
            {name}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} textAlign="center">
            {description}
          </Typography>
          <Button variant="closeButton" onClick={handleClose}>
            <FontAwesomeIcon icon={icons.close as IconProp} size="lg" />
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};
