import React from 'react';
import {
  CTASection,
  SwiperShell,
  OurProcess,
  YouTubeEmbed,
  Markdown,
} from '../shared/layout/components';
import { HomeBanner, RobotCard } from './components';
import { PortfolioSlideLayout, PortfolioSwiperShell } from '../portfolio/components';
import {
  Typography,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Link,
  Paper,
  Box,
} from '@mui/material';
import { icons } from '../icons';
import { InsightsCard, InsightSwiperShell } from '../insights/components';
import { SwiperSlide } from 'swiper/react';
import { useHome } from '../api';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Meta, LoadingIndicator, ButtonLink } from '../shared';

export const HomePage: React.FC = () => {
  const homeQuery = useHome();
  if (!homeQuery.data) return <LoadingIndicator />;

  return (
    <Box>
      <Meta seo={homeQuery.data.home.data.attributes.SEO} />
      <HomeBanner banner={homeQuery.data.home.data.attributes.PageBanner} />

      <Link href="#helpsection" sx={{ textDecoration: 'none' }}>
        <Typography variant="h2" textAlign="center" sx={{ color: 'primary.main' }}>
          How can we help you
        </Typography>
      </Link>
      <Box id="helpsection">
        <SwiperShell>
          {homeQuery.data?.home.data.attributes.RobotSlider.map(x => (
            <SwiperSlide key={x.id}>
              <RobotCard robot={x}></RobotCard>
            </SwiperSlide>
          ))}
        </SwiperShell>
      </Box>
      <Paper variant="gradient2">
        <Container>
          <OurProcess />
        </Container>
      </Paper>

      <Box py={3} textAlign="center">
        <Typography variant="h2" color="primary.main" textAlign="center">
          What our clients say
        </Typography>
        <SwiperShell>
          {homeQuery.data?.home.data.attributes.testimonials?.data.map(x => (
            <SwiperSlide key={x.id}>
              <Card>
                <CardHeader title={x.attributes.ClientName} />

                <CardContent>
                  <Typography>{x.attributes.TestimonialCopy}</Typography>
                </CardContent>
              </Card>
            </SwiperSlide>
          ))}
        </SwiperShell>
        <ButtonLink to="/about-us/client-testimonials">
          Check out more client testimonials
        </ButtonLink>
      </Box>

      <Paper variant="gradient2">
        <Container maxWidth="xl">
          <Box sx={{ width: '100%' }} p={0}>
            <Typography variant="h2" color="common.white" textAlign="center">
              Our portfolio
            </Typography>
            <PortfolioSwiperShell>
              {homeQuery.data?.home.data.attributes.portfolio_item_pages?.data.map(x => (
                <SwiperSlide key={x.id}>
                  <PortfolioSlideLayout portfolioslide={x.attributes} />
                </SwiperSlide>
              ))}
            </PortfolioSwiperShell>
          </Box>
        </Container>
      </Paper>

      <Container maxWidth="xl">
        <Typography variant="h2" color="primary.main" textAlign="center">
          Our Insights
        </Typography>
        <InsightSwiperShell>
          {homeQuery.data?.home.data.attributes.insight_articles?.data.map(x => (
            <SwiperSlide key={x.id}>
              <InsightsCard insight={x.attributes} />
            </SwiperSlide>
          ))}
        </InsightSwiperShell>
      </Container>
      <Paper variant="gradient3">
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid
              item
              flexDirection="column"
              xs={12}
              md={6}
              alignItems="start"
              justifyContent="center"
            >
              <Typography variant="h2" color="common.white">
                {homeQuery.data?.home.data.attributes.VideoSection.Headline}
              </Typography>
              <Markdown children={homeQuery.data?.home.data.attributes.VideoSection.Copy} />
              <ButtonLink to={homeQuery.data?.home.data.attributes.VideoSection.ButtonLink}>
                {homeQuery.data?.home.data.attributes.VideoSection.buttonCopy}
              </ButtonLink>
            </Grid>
            <Grid
              item
              flexDirection="column"
              xs={12}
              md={6}
              alignItems="center"
              justifyContent="center"
            >
              <YouTubeEmbed
                url={homeQuery.data?.home.data.attributes.VideoSection.YoutubeEmbedUrl}
              />
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <CTASection
        title="We are ready to help you"
        img={icons.plane as IconProp}
        link="/contact-us"
        linkCopy="Contact Us"
      />
    </Box>
  );
};
