import React from 'react';
import { CTASection, PageBanner } from '../shared/layout/components';
import { Container, Box, Grid, Typography, List, ListItem } from '@mui/material';
import { icons } from '../icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import metaImage from '../assets/images/envative-building-bg.jpg';
import { MetaStatic } from '../shared';

export const EnvativeCapabilites: React.FC = () => {
  return (
    <Box>
      <MetaStatic
        title="Envative Capabilities Statement"
        description="
Established in 1998, Envative is a 100% United States-based custom software and technology engineering firm."
        image={metaImage}
      />
      <PageBanner headline="Envative Capabilities Statement" />
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6} flexDirection="column">
            <Typography>
              Established in 1998, Envative is a 100% United States-based custom software and
              technology engineering firm. Over the past decade, we have formulated a unique process
              and culture focused on customer solutions. Every member of our team shares a passion
              for developing and delivering the highest quality software and results. Our staff
              represents the best of the best in the industry, supported by our rigorous hiring and
              mentoring practices. Trust, reliability and transparency are the bricks that have
              built our firm and support our reputation for excellence. We have served as an
              essential technology partner in healthcare, finance, security, telecommunications,
              hospitality, retail, manufacturing, robotics, military and more.
            </Typography>
            <Typography variant="h2" sx={{ color: 'primary.main' }}>
              CORE COMPETENCIES
            </Typography>
            <Grid container>
              <Grid item xs={12} lg={6} flexDirection="column">
                <Typography variant="h3" component="h5">
                  Project Management
                </Typography>
                <List sx={{ '& li': { display: 'list-item' } }}>
                  <ListItem>Agile Methodology</ListItem>
                  <ListItem>Scrum</ListItem>
                  <ListItem>Sprint Review Process</ListItem>
                  <ListItem>Budget Management</ListItem>
                  <ListItem>Status Updates</ListItem>
                  <ListItem>Internal to External Coordination</ListItem>
                </List>
                <Typography variant="h3" component="h5">
                  Design
                </Typography>
                <List sx={{ '& li': { display: 'list-item' } }}>
                  <ListItem>Design Thinking</ListItem>
                  <ListItem>UX Analysis and Review</ListItem>
                  <ListItem>Persona Development</ListItem>
                  <ListItem>Full UX Design Process</ListItem>
                  <ListItem>Status Updates</ListItem>
                  <ListItem>Prototyping</ListItem>
                </List>
              </Grid>
              <Grid item xs={12} lg={6} flexDirection="column">
                <Typography variant="h3" component="h5">
                  Development
                </Typography>
                <List sx={{ '& li': { display: 'list-item' } }}>
                  <ListItem>Web Applications</ListItem>
                  <ListItem>iOS/Android Applications</ListItem>
                  <ListItem>Machine Learning</ListItem>
                  <ListItem>Hybrid Mobile Application Development</ListItem>
                  <ListItem>Technical Architecture</ListItem>
                  <ListItem>Data Modeling</ListItem>
                  <ListItem>APIs</ListItem>
                  <ListItem>Unit & Integration Testing Strategies</ListItem>
                  <ListItem>Beacons and IoT</ListItem>
                </List>
              </Grid>
            </Grid>
            <Typography variant="h2" sx={{ color: 'primary.main' }}>
              DIFFERENTIATORS
            </Typography>
            <List sx={{ '& li': { display: 'list-item' } }}>
              <ListItem>Highly consultative team of technology experts</ListItem>
              <ListItem>Mature, proven and transparent process</ListItem>
              <ListItem>Continuous training in new and emerging technologies</ListItem>
              <ListItem>Longevity in the industry and client engagement</ListItem>
              <ListItem>Top rankings for client satisfaction and work quality</ListItem>
            </List>
          </Grid>
          <Grid item xs={12} lg={6} flexDirection="column">
            <Typography variant="h2" sx={{ color: 'primary.main' }}>
              Corporate Data
            </Typography>
            <Typography m={0}>CAGE: 4G2N0 | UEI: KTLYKN8LUXR9 | Work Area: Nationwide</Typography>
            <Typography m={0}>
              Business Name: Pullano, Mastrella and Lamb - dba: Envative
            </Typography>
            <Typography m={0}>Gov. Bus. POC: Marc Mastrella</Typography>
            <Typography m={0}>Office: (585) 327-5640 | Cell: (585) 469-9383</Typography>
            <Typography m={0}>E-Mail: mmastrella@envative.com</Typography>
            <Typography m={0}>Socio-Economic Status: Small Business</Typography>
            <Typography m={0}>
              Primary NAICS: 541511: Custom Computer Programming Services
            </Typography>
            <Typography>Additional NAICS: 541512, 541513, 541519, 541990, 511210</Typography>
            <Typography variant="h2" sx={{ color: 'primary.main' }}>
              PAST PERFORMANCE
            </Typography>
            <Typography variant="h3" component="h5">
              CAUGHT ON CAMERA (FANNIE MAE)
            </Typography>
            <Typography variant="h4" component="h6">
              Value: $350,000
            </Typography>
            <Typography>
              Project Details: Caught On Camera (CoC), a leader in Smart Home Security, was
              commissioned by Fannie Mae to test a solution to monitor vacant homes carrying
              mortgages managed by Fannie Mae, a government-sponsored mortgage association. CoC
              chose Envative to develop an IoT device to sense motion, fire, smoke, water, and asset
              location, such as a refrigerator. The device captures line-of-sight video and audio
              when an event has been initiated using cellular data services and independent battery
              power sources. The solution also included a web-based portal and mobile application to
              aggregate event data and replay video and audio streams.
            </Typography>
            <Typography variant="h3" component="h5">
              MULTI-NATIONAL FORTUNE 1000 COMPANY
            </Typography>
            <Typography variant="h4" component="h6">
              Value: $545,000
            </Typography>
            <Typography>
              Project Details: This company developed and manufactured a system specifically for
              highly sophisticated lees filtration processing. While this system was operationally
              effective, it required a large team to operate, and it wasn’t easy to manage multiple
              systems simultaneously. They commissioned Envative to develop a modern interface and
              workflows, enabling operational teams to manage multiple systems in multiple locations
              through a technically complex mobile application and private communication protocols.
              This software solution is now active worldwide.
            </Typography>
            <Typography variant="h3" component="h5">
              GREATER ROCHESTER INTERNATIONAL AIRPORT (GRIA)
            </Typography>
            <Typography variant="h4" component="h6">
              Value: $160,000
            </Typography>
            <Typography>
              Project Details: With limitations in operational software for complex parking
              facilities, the GRIA commissioned Envative to improve data collection and aggregation.
              Examples include “time in,” “time out,” vehicle counts, credit card processing data,
              ‘speed pass’ data, and license plate scanning. These had been stored in disparate data
              silos and could take up to sixty (60) minutes from data capture to prepare data for
              reporting. Envative created a data warehouse employing MS SQL and BI data integrator.
              The solution included a self‐managed, web-based reporting portal.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <CTASection
        title="We are ready to help you"
        linkCopy="Contact Us"
        link="/contact-us"
        img={icons.plane as IconProp}
      />
    </Box>
  );
};
