import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CTASection, Markdown, PageBanner, YouTubeEmbed } from '../shared/layout/components';
import { Container, Box, Grid, Typography } from '@mui/material';
import { Meta, LoadingIndicator } from '../shared';
import { icons } from '../icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useThankYouPage } from '../api';
import { PortfolioCard } from '../portfolio/components';
import { InsightsCard } from '../insights/components';

export const ThankYouPageShell: React.FC = () => {
  const { slug } = useParams();
  const thankYouPage = useThankYouPage(slug!);

  const thankYouPageContent = useMemo(() => {
    if (!thankYouPage.data?.thankYouPages.data.length) return null;

    return thankYouPage.data.thankYouPages.data[0];
  }, [thankYouPage]);

  if (!thankYouPageContent) return <LoadingIndicator />;

  return (
    <Box>
      <Meta seo={thankYouPageContent.attributes.SEO} />
      <PageBanner
        headline={thankYouPageContent.attributes.Banner.Headline}
        subCopy={thankYouPageContent.attributes.Banner.Subhead}
      />
      <Container maxWidth="lg">
        <Markdown children={thankYouPageContent.attributes.PageContent} />

        {thankYouPageContent.attributes.Video.YoutubeEmbedUrl && (
          <YouTubeEmbed url={thankYouPageContent.attributes.Video.YoutubeEmbedUrl} />
        )}

        <Grid container spacing={4} mb={5} mt={5}>
          {thankYouPageContent.attributes.portfolio_item_pages.data.length > 0 && (
            <Grid item mb={5}>
              <Typography variant="h2">Check out some work we've done for our clients</Typography>
            </Grid>
          )}
          {thankYouPageContent.attributes.portfolio_item_pages.data.map(x => (
            <Grid item xs={12} lg={6} key={x.id}>
              <PortfolioCard portfolioItem={x.attributes} />
            </Grid>
          ))}
          {thankYouPageContent.attributes.insight_articles.data.length > 0 && (
            <Grid item mb={5}>
              <Typography variant="h2">
                Here are some of our more popular Insight articles
              </Typography>
            </Grid>
          )}
          {thankYouPageContent.attributes.insight_articles.data.map(x => (
            <Grid item xs={12} lg={6} key={x.id}>
              <InsightsCard insight={x.attributes} />
            </Grid>
          ))}
        </Grid>
      </Container>

      <CTASection
        title="We are ready to help you"
        img={icons.plane as IconProp}
        link="/contact-us"
        linkCopy="Contact Us"
      />
    </Box>
  );
};
