import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import { theme } from '../theme';
import { ApolloClientProvider } from './ApolloClientProvider';
import { MetaDefaults } from './MetaDefaults';

type Props = {
  children: React.ReactNode;
};

export const Providers: React.FC<Props> = ({ children }) => (
  <ApolloClientProvider>
    <MetaDefaults />
    <BrowserRouter>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </BrowserRouter>
  </ApolloClientProvider>
);
