import React from 'react';

import { Routes, Route } from 'react-router-dom';
import { HomePage } from './homepage';
import { AboutPage, TestimonialsPage } from './about';
import { InsightsPage, InsightArticle } from './insights';
import { CareersPage, CareersDetailPage } from './careers';
import {
  ContactPage,
  ConsultationThankYouPage,
  FreeEstimationPage,
  EstimationThankYouPage,
  FreeConsultationPage,
  ContactThankYouPage,
} from './contact';
import {
  ServicesPage,
  CustomWebPage,
  IotPage,
  PrototypingPage,
  WebsitesPage,
  MobileAppsPage,
  CodeReviewsPage,
} from './services';
import { PortfolioPage, PortfolioDetailPage } from './portfolio';
import { EnvativeCapabilites, PrototypesLandingPage, LandingPageShell } from './landingpages';
import { PrivacyPolicy } from './privacy';
import { ThankYouPageShell } from './thankYou';

export const AppRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="about-us">
      <Route index element={<AboutPage />} />
      <Route path="client-testimonials" element={<TestimonialsPage />} />
    </Route>
    <Route path="/contact-us">
      <Route index element={<ContactPage />} />
      <Route path="thank-you" element={<ContactThankYouPage />} />
      <Route path="envative-consultation" element={<FreeConsultationPage />} />
      <Route path="thank-you-consultation" element={<ConsultationThankYouPage />} />
      <Route path="free-estimation" element={<FreeEstimationPage />} />
      <Route path="thank-you-estimation" element={<EstimationThankYouPage />} />
      <Route path=":slug" element={<ThankYouPageShell />} />
    </Route>

    <Route path="/insights" element={<InsightsPage />} />
    <Route path="/insights/:slug" element={<InsightArticle />} />

    <Route path="careers" element={<CareersPage />} />
    <Route path="/careers/:slug" element={<CareersDetailPage />} />

    <Route path="services-and-expertise">
      <Route index element={<ServicesPage />} />
      <Route path="code-reviews" element={<CodeReviewsPage />} />
      <Route path="custom-web-development" element={<CustomWebPage />} />
      <Route path="mobile-app-development" element={<MobileAppsPage />} />
      <Route path="software-prototyping" element={<PrototypingPage />} />
      <Route path="iot-solutions" element={<IotPage />} />
      <Route path="websites" element={<WebsitesPage />} />
    </Route>

    <Route path="portfolio" element={<PortfolioPage />} />
    <Route path="portfolio/:slug" element={<PortfolioDetailPage />} />

    <Route path="landing-pages">
      <Route path="envative-prototypes" element={<PrototypesLandingPage />} />
      <Route path="envative-capabilities" element={<EnvativeCapabilites />} />
      <Route path=":slug" element={<LandingPageShell />} />
    </Route>
    <Route path="privacy-policy" element={<PrivacyPolicy />} />
  </Routes>
);
