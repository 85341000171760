import React from 'react';
import { CTASection, PageBanner } from '../shared/layout/components';
import { Container, Box, Grid, Typography, List, ListItem, Paper } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { icons } from '../icons';
import { Link, MetaStatic } from '../shared';
import metaImage from '../assets/images/envative-building-bg.jpg';

export const ServicesPage: React.FC = () => {
  return (
    <Box>
      <MetaStatic
        title="Services and Expertise"
        description="
        Technology consulting, Software architecture and development, IoT software and device design, UI and UX design, Software testing (QA) and support programs"
        image={metaImage}
      />
      <PageBanner
        headline="Services and Expertise"
        subCopy="Talented engineers, a well-honed process and the latest technologies await you."
      />
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item lg={6} flexDirection="column">
            <Box sx={{ position: 'sticky', top: '9rem', height: 'fit-content' }}>
              <Typography variant="h2" color="primary.main">
                Expertise
              </Typography>
              <Typography>
                Envative is a 100% United States-based custom software and technology engineering
                firm. Since 1998, we have formulated a unique process and culture focused on
                customer solutions.
              </Typography>
              <Typography>
                Every member of our team shares a passion for developing and delivering the highest
                quality software and results. Our staff represents the best of the best in the
                industry, supported by our rigorous hiring and mentoring practices.
              </Typography>
              <Typography>
                Trust, reliability and transparency are the bricks that have built our firm and
                support our reputation for excellence.
              </Typography>
              <Typography>
                We have served as an essential technology partner in healthcare, finance, security,
                telecommunications, hospitality, retail, manufacturing, robotics, military and more.
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} flexDirection="column">
            <Typography variant="h3">WHY WE’RE DIFFERENT</Typography>
            <List sx={{ marginBottom: '1rem', '& li': { display: 'list-item' } }}>
              <ListItem>Highly consultative team of technology experts</ListItem>
              <ListItem>Mature, proven and transparent process</ListItem>
              <ListItem>Continuous training in new and emerging technologies</ListItem>
              <ListItem>Longevity in the industry and client engagement</ListItem>
              <ListItem>Top rankings for client satisfaction and work quality</ListItem>
            </List>
            <Typography variant="h4">WHAT WE DO</Typography>
            <List sx={{ marginBottom: '1rem', '& li': { display: 'list-item' } }}>
              <ListItem>Technology consulting, mentoring and auditing</ListItem>
              <ListItem>Software architecture and development</ListItem>
              <ListItem>Internet of Things (IoT) software and device design</ListItem>
              <ListItem>UI and UX design</ListItem>
              <ListItem>Software testing (QA) and support programs</ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
      <Paper variant="gradientGrey" id="techConsulting">
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item lg={6} flexDirection="column">
              <Box sx={{ position: 'sticky', top: '9rem', height: 'fit-content' }}>
                <Typography variant="h2" color="secondary.main">
                  TECHNICAL CONSULTING
                </Typography>
                <Typography>
                  Consulting services include needs evaluations and ideations of possible technology
                  solutions for business challenges, including journey mapping, requirements
                  definition, full scope definition and project-risk assessment.
                </Typography>
                <Typography>
                  In addition, we provide flexible mentoring engagements that include helping
                  clients’ internal teams with complex development challenges through code reviews,
                  use case demonstrations and prototyping.
                </Typography>
                <Typography variant="h4">
                  Are you in need of advice on new or existing technology solutions?
                </Typography>
                <Typography>
                  <Link to="/contact-us/envative-consultation">Let's Get Started</Link>
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} flexDirection="column">
              <Typography variant="h3">Technical Consulting Details: </Typography>
              <List sx={{ marginBottom: '1rem', '& li': { display: 'list-item' } }}>
                <ListItem>Ideations of proposed solutions</ListItem>
                <ListItem>Assessments of software scalability</ListItem>
                <ListItem>Reviews of security standards and potential vulnerabilities</ListItem>
                <ListItem>Performance and code quality audits</ListItem>
                <ListItem>Software reengineering</ListItem>
                <ListItem>Software code refactoring to improve code maintainability</ListItem>
              </List>
              <Typography variant="h4" component="p">
                Learn more about our technical consulting services:
              </Typography>
              <Typography>
                <Link to="/services-and-expertise/code-reviews">Code Review Services</Link>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Container maxWidth="xl" id="softwareArchitecture">
        <Grid container spacing={4}>
          <Grid item lg={6} flexDirection="column">
            <Box sx={{ position: 'sticky', top: '9rem', height: 'fit-content' }}>
              <Typography variant="h2" color="secondary.main">
                SOFTWARE ARCHITECTURE AND DEVELOPMENT
              </Typography>
              <Typography>
                Our repeatable engineering methodology translates to the highest quality work
                product and user experiences at predictable costs for our clients.
              </Typography>
              <Typography>
                Our web and mobile architectures focus on technical resilience, flexible change
                management and optimized cost of ownership. In addition, specific considerations
                regarding compliance with industry-specific regulations and standards (e.g., HIPAA,
                GDPR, PCI DSS, GLBA) are a priorities in our planning.
              </Typography>
              <Typography variant="h4">Do you have a custom software project idea?</Typography>
              <Typography>
                <Link to="/contact-us/envative-consultation">Get your free consultation</Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} flexDirection="column">
            <Typography variant="h3">Software Development Details: </Typography>
            <Typography variant="h4">Non-Specific Categories include:</Typography>
            <List sx={{ marginBottom: '1rem', '& li': { display: 'list-item' } }}>
              <ListItem>Microservices</ListItem>
              <ListItem>Cloud-native architecture design</ListItem>
              <ListItem>DevOps services</ListItem>
              <ListItem>Advanced ETL and data services</ListItem>
            </List>
            <Typography variant="h4">Mobile Applications</Typography>
            <Typography>
              Mobile apps for smartphones, tablets and wearables with IoT features.
            </Typography>
            <List sx={{ marginBottom: '1rem', '& li': { display: 'list-item' } }}>
              <ListItem>Process automation – automating labor-intensive businesses</ListItem>
              <ListItem>
                Multimedia tools – video and audio streaming, image processing and management
              </ListItem>
              <ListItem>
                Internal corporate – enhancing existing ERP systems with employee, customer and
                supplier interactions
              </ListItem>
              <ListItem>IoT interface – aggregating sensor data and managing processes</ListItem>
            </List>
            <Typography variant="h4">Web Applications</Typography>
            <Typography>
              Enterprise web apps to streamline workflows, raise productivity, and drive
              collaboration, services and user engagement.
            </Typography>
            <List sx={{ marginBottom: '1rem', '& li': { display: 'list-item' } }}>
              <ListItem>Ecommerce web apps, including headless commerce</ListItem>
              <ListItem>Customer and self-service portals</ListItem>
              <ListItem>
                Customer service apps (incl. call center and self-service solutions)
              </ListItem>
              <ListItem>Artificial intelligence apps</ListItem>
            </List>
            <Typography variant="h4">Learn more about our software development:</Typography>
            <Typography>
              <Link to="/services-and-expertise/custom-web-development">Custom Web Services</Link>
            </Typography>
            <Typography>
              <Link to="/services-and-expertise/mobile-app-development">
                Mobile App Development Services
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Paper variant="gradientGrey" id="iot">
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item lg={6} flexDirection="column">
              <Box sx={{ position: 'sticky', top: '9rem', height: 'fit-content' }}>
                <Typography variant="h2" color="secondary.main">
                  IOT SOFTWARE AND DEVICE DESIGN
                </Typography>
                <Typography>
                  Projects involving even the simplest Internet of Things (IoT) products must
                  integrate technical elements and effectively coordinate the activities of experts
                  from many engineering disciplines and stakeholders.
                </Typography>
                <Typography>
                  Challenges in system testing, security, firmware, regulatory compliance, data
                  governance and user privacy require an experienced organization and staff. Our
                  design and development capabilities stretch across the complete IoT technology
                  stack.
                </Typography>
                <Typography variant="h4" component="p">
                  Looking to get started with IoT?
                </Typography>
                <Typography>
                  <Link to="/contact-us/envative-consultation">Get your free consultation</Link>
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} flexDirection="column">
              <Typography variant="h3">IoT Service Details: </Typography>
              <Typography variant="h4">IoT Device Development: </Typography>
              <Typography>
                {' '}
                Hardware devices, such as sensors, actuators, appliances or machines programmed to
                transmit data over the internet or other networks.
              </Typography>
              <List sx={{ marginBottom: '1rem', '& li': { display: 'list-item' } }}>
                <ListItem>Embedded software</ListItem>
                <ListItem>Networking protocols (ex. WiFi 4G/5G, BLE, MQTT and Ethernet)</ListItem>
                <ListItem>Device-to-device, device-to-server (Cloud) communication</ListItem>
                <ListItem>
                  Application-ready systems-on-chip, single-board computers or completely custom
                  board design
                </ListItem>
              </List>
              <Typography variant="h4">IoT Application Development:</Typography>
              <Typography>
                Web and mobile interface applications used to aggregate data from physical objects
                and intelligently analyze the data for process automation and business intelligence.
              </Typography>
              <List sx={{ marginBottom: '1rem', '& li': { display: 'list-item' } }}>
                <ListItem>
                  Preventive maintenance – remotely monitoring equipment performance metrics for
                  early detection of potential malfunction
                </ListItem>
                <ListItem>
                  Process automation – ranging from the simple switching of on/off and
                  opening/closing to complex industrial processes, like robotic product dispensing
                </ListItem>
                <ListItem>
                  Environmental monitoring – gathering and reporting environmental variables like
                  temperature, humidity, and CO2 levels in agriculture and bioengineering
                </ListItem>
                <ListItem>
                  Asset management – including tagging and monitoring the movement and GeoPosition
                  of assets throughout a manufacturing facility or over the road while trucking
                  across the country
                </ListItem>
              </List>
              <Typography variant="h4">Learn more about our IoT services</Typography>
              <Typography>
                <Link to="/services-and-expertise/iot-solutions">
                  IoT Software and Device Design Solutions
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Container maxWidth="xl" id="uiux">
        <Grid container spacing={4}>
          <Grid item lg={6} flexDirection="column">
            <Box sx={{ position: 'sticky', top: '9rem', height: 'fit-content' }}>
              <Typography variant="h2" color="secondary.main">
                UI AND UX DESIGN
              </Typography>
              <Typography>
                UI/UX services focus on building smooth interaction flows between users and
                software. Our technical and aesthetic approach to UI/UX design ensures superior user
                experience, resulting in maximized user adoption of all types of web and mobile
                software features.
              </Typography>
              <Typography>
                Our prototyping approach to design helps to crystallize requirements and the
                development road map eliminating unexpected project risk.
              </Typography>
              <Typography variant="h4" component="p">
                Do you have a project that needs a prototype?
              </Typography>
              <Typography>
                <Link to="/contact-us/envative-consultation">Talk with our experts</Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} flexDirection="column">
            <Typography variant="h3">UI and UX Design Details: </Typography>
            <List sx={{ marginBottom: '1rem', '& li': { display: 'list-item' } }}>
              <ListItem>UI and UX design teams with years of practical experience</ListItem>
              <ListItem>Responsive and performance-driven design</ListItem>
              <ListItem>Focus on user types and journeys</ListItem>
              <ListItem>Adherence to regulation requirements (ex. SEO)</ListItem>
              <ListItem>Well-established iterative processes</ListItem>
            </List>
            <Typography variant="h4">Learn more about our UI and UX design:</Typography>
            <Typography>
              <Link to="/services-and-expertise/software-prototyping">Prototyping Services</Link>
            </Typography>
            <Typography>
              <Link to="/services-and-expertise/websites">CMS Website Development</Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Paper variant="gradientGrey" id="testing">
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item lg={6} flexDirection="column">
              <Box sx={{ position: 'sticky', top: '9rem', height: 'fit-content' }}>
                <Typography variant="h2" color="secondary.main">
                  TESTING AND SUPPORT
                </Typography>
                <Typography>
                  Quality is a cornerstone of software development. We provide top-quality assurance
                  and software testing to verify error-free software product operation, ensuring the
                  quality and undisrupted functionality of our developed systems.
                </Typography>
                <Typography>
                  We also provide application support and maintenance services to ensure that all
                  software is highly available, reliable and responsive to evolving business needs.
                </Typography>
                <Typography>
                  <Link to="/contact-us/envative-consultation">We can help you</Link>
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} flexDirection="column">
              <Typography variant="h3">Testing and Support Details:</Typography>
              <Typography variant="h4">Support and Maintenance:</Typography>
              <List sx={{ marginBottom: '1rem', '& li': { display: 'list-item' } }}>
                <ListItem>
                  Ongoing maintenance – troubleshooting, bug fixing, backups and stability
                  monitoring
                </ListItem>
                <ListItem>Enhancements and upgrades; new functionality integration</ListItem>
                <ListItem>
                  Software solution reengineering in response to business-need changes
                </ListItem>
                <ListItem>Platform migrations</ListItem>
              </List>
              <Typography variant="h4">QA and Testing:</Typography>
              <List sx={{ marginBottom: '1rem', '& li': { display: 'list-item' } }}>
                <ListItem>Manual and automated testing</ListItem>
                <ListItem>Unit, API and UI testing</ListItem>
                <ListItem>Exploratory and regression testing</ListItem>
                <ListItem>Functional testing</ListItem>
                <ListItem>Integration testing</ListItem>
                <ListItem>Compatibility and localization testing</ListItem>
                <ListItem>Security testing</ListItem>
                <ListItem>Usability testing</ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <CTASection
        title="We are ready to help you"
        linkCopy="Contact Us"
        link="/contact-us"
        img={icons.plane as IconProp}
      />
    </Box>
  );
};
