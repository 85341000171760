import React from 'react';
import { Link, Stack, Box, Typography } from '@mui/material';
import { Achievement } from '../../../api';
import { Image } from '../../components';

type Props = {
  featuredAchievement: Achievement;
};

export const RecentAwardsItem: React.FC<Props> = ({ featuredAchievement }) => {
  return (
    <Link
      href={featuredAchievement.link}
      sx={{ textDecoration: 'none', width: '100%' }}
      display="flex"
    >
      <Stack sx={{ width: '100%' }}>
        <Box
          sx={{
            backgroundColor: 'transparent.white',
            flex: '1 1 auto',
            border: 'solid thin',
            borderColor: 'transparent.white',
          }}
          p={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Image
            file={featuredAchievement.image.data?.attributes}
            sx={{ borderRadius: '1rem', width: '100%', height: 'fit-content' }}
          />
        </Box>

        <Typography textAlign="center" p={1} m={0}>
          {featuredAchievement.name}
        </Typography>
      </Stack>
    </Link>
  );
};
