import React from 'react';
import { Typography, List, ListItem, Link } from '@mui/material';
import ReactMarkdown from 'react-markdown';
type Props = {
  children: string;
};

export const Markdown: React.FC<Props> = ({ children }) => (
  <ReactMarkdown
    children={children}
    components={{
      p: ({ node, ...props }) => <Typography {...props} />,
      a: ({ node, ...props }) => <Link {...props} />,
      h1: ({ node, ...props }) => <Typography variant="h1" {...props} />,
      h2: ({ node, ...props }) => <Typography variant="h2" {...props} />,
      h3: ({ node, ...props }) => <Typography variant="h3" {...props} />,
      h4: ({ node, ...props }) => <Typography variant="h4" {...props} />,
      h5: ({ node, ...props }) => <Typography variant="h5" {...props} />,
      h6: ({ node, ...props }) => <Typography variant="h6" {...props} />,
      ul: ({ node, ...props }) => (
        <List {...props} sx={{ marginBottom: '1rem', '& li': { display: 'list-item' } }} />
      ),
      li: ({ node, ...props }) => <ListItem {...props} />,
    }}
  />
);
