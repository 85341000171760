import React from 'react';
import { Container, Stack, Typography, Paper, styled, Button, Grid } from '@mui/material';
import robot from '../../../assets/images/software-development-robot.png';
import { useFooter } from '../../../api';
import { LoadingIndicator, Image } from '../../components';
import { Markdown } from '../components';

const Robot = styled('img')(({ theme }) => ({
  bottom: '0',
  right: '0',
  zIndex: '2',
  [theme.breakpoints.up('xs')]: {
    position: 'relative',
  },

  [theme.breakpoints.up('lg')]: {
    position: 'absolute',
  },
}));

export const Footer: React.FC = () => {
  const footerQuery = useFooter();
  if (!footerQuery.data) return <LoadingIndicator />;
  return (
    footerQuery.data.footer.data && (
      <Paper variant="pageFooter">
        <Container maxWidth="xl">
          <Grid container spacing={0}>
            <Grid item xs={12} md={8}>
              <Stack>
                <Typography variant="h3" textAlign="left">
                  {footerQuery.data.footer.data.attributes.Tagline}
                </Typography>
                <Grid container spacing={0} sx={{ marginBottom: '2rem' }}>
                  {footerQuery.data.footer.data.attributes.SocialLinks.map(x => (
                    <Grid item xs={4} sm={2} key={x.id}>
                      <Button href={x.SocialLink}>
                        <Image
                          file={x.Icon.data.attributes}
                          sx={{
                            maxWidth: '2.5rem',
                            height: 'auto',
                            minHeight: '2rem',
                            padding: '.5rem',
                          }}
                        />
                      </Button>
                    </Grid>
                  ))}
                </Grid>
                <Markdown children={footerQuery.data.footer.data.attributes.Address} />
                <Stack direction="row" alignItems="center">
                  <Grid container spacing={0}>
                    {footerQuery.data.footer.data.attributes.Badges.map(x => (
                      <Grid item xs={6} sm={2} key={x.id}>
                        <a target="_blank" rel="noreferrer" href={x.Link}>
                          <Image
                            file={x.Image.data.attributes}
                            sx={{ maxWidth: '100%', height: 'auto' }}
                          />
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} sx={{ alignItems: 'flex-end' }}>
              <Robot
                src={robot}
                alt="Envative Robot"
                sx={{ width: '100%', maxWidth: 'fit-content', height: 'min-content' }}
              />
            </Grid>
          </Grid>
        </Container>
      </Paper>
    )
  );
};
