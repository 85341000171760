import { useMemo } from 'react';
import { File } from '../../api';

export type ImageSize = 'large' | 'medium' | 'small' | 'thumbnail';

export function useMediaUrl(image?: File | null, size?: ImageSize) {
  return useMemo(() => {
    if (!image) return null;

    const url = size && image.formats?.[size] ? image.formats[size].url : image.url;

    switch (image.provider) {
      case 'local':
        return `${process.env.REACT_APP_STRAPI_API_ROOT}${url}`;
      case 'aws-s3':
      default:
        return url;
    }
  }, [image, size]);
}
