import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title: string;
  description: string;
  image: string;
}

export const MetaStatic: React.FC<Props> = ({ title, description, image }) => {
  return (
    <Helmet defaultTitle="Envative" titleTemplate="%s - Envative">
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {title && <meta property="og:title" content={title} />}
      {image && <meta property="og:image:type" content="image/png" />}
      {image && <meta name="image" property="og:image" content={image}></meta>}
      {title && <meta name="twitter:card" content="summary_large_image" />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  );
};
