import { gql } from '@apollo/client';

export const FILE_FIELDS_FRAGMENT = gql`
  fragment FileFields on UploadFileEntity {
    id
    attributes {
      name
      provider
      url
      alternativeText
      formats
      ext
    }
  }
`;
