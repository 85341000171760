import React, { ReactNode, useState } from 'react';
import { Collapse, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../icons';

interface Props {
  title: ReactNode;
  children: ReactNode;
}

export const Collapsable: React.FC<Props> = ({ title, children }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Stack mb={1}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ cursor: 'pointer', pb: 2 }}
        onClick={() => setExpanded(!expanded)}
      >
        {title}
        <FontAwesomeIcon icon={expanded ? icons.collapse : icons.expand} />
      </Stack>
      <Collapse in={expanded}>
        <div>{children}</div>
      </Collapse>
    </Stack>
  );
};
